.ce_newsdetail {
  max-width:100%;

  .subline {
    a {
      font-size:1.6em;
      text-transform:uppercase;
      text-decoration:underline;
    }
  }

  .news {
    position:relative;
    margin-top:9.3em;
    padding:0 5px;

    .image {
      position:absolute;
      right:0;
      top:-10em;
      max-width:58%;
    }

    .content {

      position:relative;
      padding:5em 8em;
      bottom:auto;
      background:$clr-white;
      width:45%;
      margin:0 0 0 5%;
      z-index:2;

      .title {
        font-size:2em;
        font-weight:700;
        line-height:1.2;
        @include breakpoint(lt1000) {
          font-size:16px;
        }
      }

      .date {
        font-size:1.6em;
        margin-top:1em;
        @include breakpoint(lt1000) {
          font-size:14px;
        }
      }

      .text {
        min-height:20em;
        font-size:1.6em;
        margin-top:3em;
        @include breakpoint(lt1000) {
          font-size:14px;
        }
        @include breakpoint(lt400) {
          margin-top:1em;
        }
        a {
          color:$clr-black;
          &:hover {
            color:$clr-green;
          }
        }
      }

      .btn.stroke {
        display:block;
        .rel .hov {
          background-color:$clr-lgreen;
        }
      }

    }

    @include breakpoint(lt400) {
      padding:0 20px;
      margin-top:0;
      .image {
        position:static;
        max-width:100%;
      }
      .content {
        width:100%;
        margin:0;
        .title {
          font-size:16px;
        }
        .date {
          font-size:15px;
        }
        .text {
          font-size:14px;
        }
      }
    }
  }


  .slick-arrow.big.slick-prev {
    right:auto;
    top:58em;
    bottom:auto;
    left:0;
    border-color:black;
    color:black;
  }


  .slick-arrow.big.slick-next {
    right:auto;
    top:58em;
    bottom:auto;
    left:52%;
    border-color:black;
    color:black;
  }

  .slick-arrow.big {


    .icon {
      left:0;
      font-weight:700;
      &:hover {
        background-color:white;
        border-color:white;
        color:black;
      }
    }
  }

  @include breakpoint(lt400) {
    .slick-arrow.big.slick-prev {
      right:auto;
      top:50%;
      bottom:auto;
      left:20px;
      border-color:black;
      color:black;
    }


    .slick-arrow.big.slick-next {
      left:auto;
      top:50%;
      bottom:auto;
      right:20px;
      border-color:black;
      color:black;
    }
  }


}