/*  ==========================================================================
    MIXINS
    Collection of all mixins (mind the ordering!)
    ========================================================================== */

@import 'lib/general';
@import 'lib/buttons';

@mixin breakpoint($point) {
  @if $point == fullscreen {
    @media only screen and (min-aspect-ratio: 1/1) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 2/3) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 3/2) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 3/4) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 4/3) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 4/5) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 5/3) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 5/4) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 16/9) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 16/10) and (min-height: 680px) and (min-width: 768px),
    screen and (min-aspect-ratio: 17/9) and (min-height: 680px) and (min-width: 768px) { @content; }
  }


  @else if $point == lt1700 {
    @media only screen and (max-width: 1699px) { @content; }
  }
  @else if $point == lt1400 {
    @media only screen and (max-width: 1399px) { @content; }
  }
  @else if $point == lt1250 {
    @media only screen and (max-width: 1249px) { @content; }
  }
  @else if $point == lt1050 {
    @media only screen and (max-width: 1050px) { @content; }
  }
  // point where main_nav will turn responsive "tablet"
  @else if $point == lt1000 {
    @media only screen and (max-width: 1024px) { @content; }
  }
  @else if $point == gt1000 {
    @media only screen and (min-width: 1025px) { @content; }
  }
  @else if $point == gt768 {
    @media only screen and (min-width: 768px) { @content; }
  }
  @else if $point == lt768 {
    @media only screen and (max-width: 767px) { @content; }
  }
  // <= iPhone+
  @else if $point == lt400 {
    @media only screen and (max-width: 576px) { @content; }
  }
  @else if $point == gt400 {
    @media only screen and (min-width: 577px) { @content; }
  }
}


@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin dashlist($left) {


  ul {
    li {
      list-style:none;
      position:relative;
      &:before {
        content: "-";
        position:absolute;
        left:$left;
        top:0;
      }

      @include breakpoint(lt1000) {
          font-size:14px;
      }
    }
  }

  ol {
    list-style-type:decimal!important;
    li {
      @include breakpoint(lt1000) {
        font-size:14px;
      }
    }
  }
}

@mixin truncator(){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin bigger($fak) {
  transform: scale($fak);
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

@keyframes pulse_animation {
  0% { font-size: 1em; }
  30% { font-size: 1em; }
  40% { font-size: 1.2em; }
  50% { font-size:  1em; }
  60% { font-size:  1.2em; }
  70% { font-size: 1em; }
  80% { font-size:  1em; }
  100% { font-size:  1em; }
}

@keyframes fade_out {
  0% { opacity: 1; }
  80% { opacity: 0.5; }
  100% { opacity: 0; }
}