/* typo */
$std_font_family : 'Barlow';
$alt_font_family : 'Noto Serif';
$out_font_family : 'Noto Serif';

/* colors */
$clr-white  : #FFFFFF;
$clr-black  : #000000;
$clr-green  : #8AAB8C;
$clr-grey   : #2B2E34;
$clr-lgreen : #DBE5DC;

/* sizes */
$max-size : 1920px;
$size-1 : 1640px;
$size-1p : 90%;
$size-2 : 1100px;
$size-2p : 60%;
$size-3 : 1365px;
$size-3p : 75%;

$contentmargin-top: 13em;

