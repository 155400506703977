.static_fixedcontact {
  position: fixed;
  right: 1em;
  top: 45%;
  z-index: 1001;

  &.tablet_only {
    display: none;
    @include breakpoint(lt1000) {
      @include breakpoint(gt400) {
        display: block;
      }
    }
  }

  .js_saved-content {
    display: block;
    position: absolute;
    top: 15px;
    left: -15px;
    background-color: $clr-green;
    border-radius: 100%;
    width: 22px;
    height: 22px;

    color: $clr-white;
    text-align: center;
    font-size: 11px;
    z-index: -1;
    line-height: 22px;
    font-weight: 700;
    animation-name: pulse_animation;
    animation-duration: 1800ms;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &.mobile {
      left: auto;
      right: 5px;
      top: 5px;
      background-color: $clr-white;
      color: black;
      animation-name: unset;
      z-index: 2;
    }
  }


  @include breakpoint(lt400) {
    padding: 0 5%;
  }
  a {
    display: block;
    color: $clr-black;
    .icon {
      font-size: 30px;

    }
    &:hover {
      color: $clr-green;
    }
    padding: 10px 2px 10px 10px;
    @include breakpoint(lt400) {
      padding: 10px;
    }
  }

  @include breakpoint(lt1000) {
    a .icon {
      font-size: 25px;
    }
    top: 35%;
  }

  .btn {
    display: none;
  }

  @include breakpoint(lt1000) {

    .js_saved-content {
      visibility:hidden;
      &.mobile {
        visibility:visible;
      }
    }

    &.tablet_only {
      right: 0;
      top: unset;
      bottom: -2px;
      width: 100%;

      .maxwidth {

        margin: auto;
        width: 375px;
        max-width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-content: stretch;
        a {
          display: inline-block;
          height: 50px;
          overflow: hidden;
          padding: 10px;
          &:hover {
            color: $clr-black;
          }
          .icon {
            font-size: 30px;
          }

          margin-right: 2px;

          &.call {
            background-color: $clr-black;
            color: $clr-white;
          }

          &.mail, &.voucher {
            background-color: $clr-black;
            color: $clr-white;
            display: none;
          }

          &.book, &.inquiry {
            background-color: $clr-green;
            color: $clr-black;
            font-size: 14px;
            line-height: 30px;
            text-transform: uppercase;
            font-weight: 500;
            padding: 10px 0;
            &:hover {
              background-color: #536754;
              color: $clr-white;
            }
          }

          &.book, &.inquiry {
            flex-grow: 1;
          }

          &.whatsapp {
            display: none;
          }

          &.inquiry {
            margin-right: 0;
          }
        }

        .btn {
          display: inline-block;
        }
      }
    }
  }

  @include breakpoint(lt400) {

    right: 0;
    top: unset;
    bottom: -2px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: stretch;
    a {
      display: inline-block;
      height: 50px;
      overflow: hidden;
      &:hover {
        color: $clr-black;
      }
      .icon {
        font-size: 30px;
      }

      margin-right: 2px;

      &.call {
        background-color: $clr-black;
        color: $clr-white;
      }

      &.mail, &.voucher {
        background-color: $clr-black;
        color: $clr-white;
        display: none;
      }

      &.book, &.inquiry {
        background-color: $clr-green;
        color: $clr-black;
        font-size: 14px;
        line-height: 30px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 10px 0;
        &:hover {
          background-color: #536754;
          color: $clr-white;
        }
      }

      &.book, &.inquiry {
        flex-grow: 1;
      }

      &.whatsapp {
        display: none;
      }

      &.inquiry {
        margin-right: 0;
      }
    }

    .btn {
      display: inline-block;
    }
  }
}