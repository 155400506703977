.ehot_offer_detail {
  max-width:100%;

  .subline {
    a {
      font-size:1.6em;
      text-transform:uppercase;
      text-decoration:underline;
    }
  }

  ul {
    margin:0 0 1em 0!important;
  }

  .offer {
    position:relative;
    margin-top:9.3em;
    padding:0 5px;

    .image {

      &.mobile {display:none}

      position:absolute;
      right:0;
      top:-10em;
      max-width:54%;

      @include breakpoint(lt1700) {
        max-width:45%;
      }

      @include breakpoint(lt1000) {
        &.mobile {display:block; min-height:230px;}
        &.desktop {display:none}
        position:relative;
        max-width:100%;
      }

      .badge {
        position:absolute;
        height:27em;
        width:27em;
        background-color:$clr-green;
        bottom:-12em;
        left:-3em;
        text-align:center;
        display:table-cell;
        vertical-align:center;
        border-radius:100%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        padding: 10px;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;

        > div {
          width:100%;
        }
        .title {
          color:$clr-black;
          font-size:1.5em;
          text-transform:uppercase;
          display:block;
          font-weight:700;
          padding:0 2em;

          letter-spacing:1px;
          line-height:1.5;
          text-align:center;

          @include breakpoint(lt1000) {
            font-size:12px;
            padding:0 0.5em;
          }
        }

        .rate {
          color:$clr-white;
          font-size:4em;
          font-family: 'Noto Serif', serif;
          display:block;
          letter-spacing:1px;
          line-height:1.5;
          .suffix {
            font-size:70%;
            &.onrequest {
              font-size:60%;
            }
          }
        }
      }


    }

    .content {

      position:relative;
      padding:2em 14em 8em 14em;
      bottom:auto;
      background:$clr-white;
      width:90%;
      margin-left:5%;

      @include dashlist(-1em);

      .icon {
        font-size:2em;
        max-width:45%;
        margin:0 0 3em 0;
        position:relative;
        right:-0.7em;
        @include breakpoint(lt1700) {
          max-width:50%;
        }
      }



      > .title {
        font-size:5em;
        max-width:40%;
        min-height:7em;
        font-family:$alt_font_family;
        display:flex;
        align-items:center;
      }

      .date {
        font-size:2.5em;
        color:$clr-green;
        font-weight:700;
      }

      .teaser {
        font-size:2.5em;
        margin-top:10px;
        color:$clr-green;
      }

      .text {
        margin:4em 0;
        font-size:1.6em;
      }

      .buttons {
        margin-bottom:4em;
        .var1 {
          background-color: rgba(138, 171, 140, 0.2);
          &:hover {
            background-color:$clr-lgreen;
          }
        }
        .var2 {
          background-color: rgba(138, 171, 140, 0.56);
          &:hover {
            background-color:$clr-green;
          }
        }
      }

      .btn.stroke {
        display:block;
        .rel .hov {
          background-color:$clr-lgreen;
        }
      }

    }

    @include breakpoint(lt1000) {
      padding:0;
      margin-top:0;
      .image {
        top:auto;
        right:auto;
        .badge {
          bottom:auto;
          top:-4em;
          left:50%;
          margin-left:-13.5em;
        }
      }
      .content {

        padding:2em 0em 4em 0em;
        width:100%;
        margin-left:0;

        .icon.cat {
          max-width:93%;
          margin:auto;
        }

        .icon {
          position:static;
        }



        > .title {
          max-width:65%;
          min-height:unset;

          margin:auto;
          margin-bottom:2em;

        }

        .date {
          max-width:65%;
          margin:auto;
          font-size:15px;
          margin-top:2em;
          line-height:1.2;
        }

        .teaser {
          max-width:65%;
          margin:auto;
          font-size:15px;
          line-height:1.2;
        }

        .text {
          max-width:65%;
          margin:auto;
          margin-top:2em;
          font-size:14px;
        }

        .buttons {
          margin-top:4em;

          .var1, .var2 {
            max-width:unset;
          }
        }

        .btn.stroke {
          max-width:90%;
          margin:auto;
          margin-top:1em;
        }

      }

    }
  }

  .slick-slider {
    position:static;
    @include breakpoint(lt1000) {
      position:relative;
    }
  }

  .slick-arrow.big.slick-prev {
    right:auto;
    top:80em;
    bottom:auto;
    left:0;
    border-color:black;
    color:black;
    @include breakpoint(lt1000) {
      top:120em;
      border-color:$clr-green;
      color:$clr-green;
      &:hover {
        color:$clr-white;
        background-color:$clr-green;
      }
    }
  }


  .slick-arrow.big.slick-next {
    right:0;
    top:80em;
    bottom:auto;
    left:auto;
    border-color:black;
    color:black;
    @include breakpoint(lt1000) {
      top:120em;
      border-color:$clr-green;
      color:$clr-green;
      &:hover {
        color:$clr-white;
        background-color:$clr-green;
      }
    }
  }

  .slick-arrow.big {
    .icon {
      left:0;
      font-weight:700;
      &:hover {
        background-color:white;
        border-color:white;
        color:black;
      }
    }

    @include breakpoint(lt400) {
      width:30px;
      height:30px;

      .icon {
        font-size:30px;
      }
    }
  }

  @include breakpoint(lt1000) {
    .auto_slide {
      max-width:90%;
      margin:auto;
    }
  }


}