.ehot_rooms_list {

  .roomcats {
    background-color: #fff;
    z-index: 1000;
  }
  @include breakpoint(lt1000) {
    .filter .btn.icon {
      width:100%!important;
    }
  }

  padding-bottom:2em;
  .table th, .table td {
    border-top:1px solid $clr-lgreen;

  }
  .table {
    border-collapse:collapse;
  }
  .table tr {

    &:first-child, &.nobor {
      td, th {
        border-top:0;
      }
    }
  }
  .room {
    padding:5em 0;

    .btn.stroke {
      .rel {
        .hov {
          background-color: $clr-lgreen;
        }
      }

      &.fix {
        .hov {
          display:inline-block;
        }
      }

    }

    .teaser {
      position:relative;

      .desc {
        position:relative;
        .head {
          font-family:$alt_font_family;
          font-size:5em;
          min-height:3em;
          margin:0;
          @include breakpoint(lt400) {
            min-height:unset;
          }
        }

        .subhead {
          color:$clr-green;
          font-size:2.5em;
          margin-top:1em;
          @include breakpoint(lt400) {
            font-size:14px;
          }
        }

        .controls {
          margin-top:4em;
          .btn {
            width:100%;
            margin-top:1em;
          }
        }

        .buttons {
          text-align:right;
          margin-top:9em;
          margin-right:-7em;
          .btn {
            margin-left:1em;
          }

          .var1 {
            margin-right:1em;
            background-color: rgba(138, 171, 140, 0.2);
            &:hover {
              background-color:$clr-lgreen;
            }
          }
          .var2 {
            background-color: rgba(138, 171, 140, 0.56);
            &:hover {
              background-color:$clr-green;
            }
          }
        }

        .badge {
          > div {
            width:100%;
            padding:4.1em;
            @include breakpoint(lt400) {
              padding:0;
            }
          }


          position:absolute;
          height:19em;
          width:19em;
          background-color:$clr-green;
          bottom:6em;
          right:-23em;
          text-align:center;
          display:table-cell;
          vertical-align:center;
          border-radius:100%;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -ms-flex-align: center;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;

          @include breakpoint(lt400) {
            height:24em;
            width:24em;
            margin-right:-12em!important;
            top:auto!important;
            bottom:55em;

            &.autopos {
              display:none;
            }
          }

          .title {
            color:$clr-black;
            font-size:1.4em;
            font-weight:700;
            text-transform:uppercase;
            letter-spacing:1px;
            @include breakpoint(lt400) {
              font-size:10px!important;
              padding:0 1.5em;
            }
          }

          .rate {
            color:$clr-white;
            font-size:2.8em;
            font-weight:700;
            font-family: 'Noto Serif', serif;
            @include breakpoint(lt400) {
              font-size:24px!important;
            }
            .suffix {
              font-size:80%;
              &.onrequest {
                font-size:60%;
              }
            }
          }
        }
      }

      .content {
        width:30%;
        float:left;



        .oversized {
          width:125%;
          background:white;
          position:relative;
          z-index:2;
          padding:6em 7em 0 0;
          margin-top:11em;
        }

        h2 {
          color:$clr-green;
          font-size:2.5em;
          margin-bottom:1em;
        }
      }

      .images {
        width:70%;
        float:right;
        .image.lightbox .icon {
          left:auto;
          right:5px;
          width:20px;
          height:25px;
        }

        .badge {
          display:none;
        }
        &.mobile {
          margin-top:4em;
          position:relative;

          .badge {
            display:flex;
            top:-4em!important;
            left:50%;
            margin-left:-12em;
          }

        }
      }

      @include breakpoint(lt400) {
        .content, .images {
          width:100%;
          float:none;
        }

        .content {
          .oversized {
            width:100%;
            margin-top:0;
            padding:2em 0;

            .desc .buttons {
              margin:5em 0 0 0;
            }
          }
        }

        .desc {
          .badge {
            top:10em;
            right:50%;
            margin-right:-8em;
            .title {
              font-size:2em;
            }
            .rate {
              font-size:3.5em;
            }
          }
          .controls {
            margin-top:8em;
          }
        }

      }
    }
    .details {

      width:100%;
      min-height:200px;
      margin-top:4em;

      border-bottom:1px solid #dee2e6;

      display:none;

      .tab-pane {
        ul {
          margin-top:0;
        }
      }


      ul.nav {
        list-style:none inside none;
        text-transform:uppercase;
        font-size:1.6em;
        margin:0;
        .nav-link {
          padding:1em 0;
          text-align:center;
          max-width:260px;
          width:260px;
          border-radius:0;
          font-weight:500;
          color: $black;
          &.active {
            color: $clr-green;
          }
          @include breakpoint(lt1000) {
            width:180px;
          }
        }
      }

      .tab-content {
        @include dashlist(-1em);
        padding:6em 15em;
        @include breakpoint(lt1000) {
          padding:2em;
        }
      }

      .text {
        font-size:1.6em;
        @include breakpoint(lt1000) {
          p, li {
            font-size:14px;
          }
        }
        .btn {
          font-size:1em;
        }
      }

      .ratecontrol {
        padding:0 0 4em 0;
        @include breakpoint(lt1000) {
          padding:1em 0 1em 0;
        }
        .seasonswitch {
            .btn {
              width:100%;
              margin-bottom:1em;
              @include breakpoint(lt400) {
                font-size:13px;
              }
              &.act {
                color:$clr-green;
              }
            }
        }

        .rateswitch {
          text-align:center;
          .head {
            font-size:2.3em;
            font-weight:bold;
            @include breakpoint(lt400) {
              font-size:13px;
            }
          }
          .desc {
            font-size:1.6em;
            margin-top:-1.1em;
            @include breakpoint(lt400) {
              font-size:13px;
            }
          }
        }
      }
      .rateplanswitch {
        visibility:hidden;
        @include breakpoint(lt400) {

            visibility:visible;

        }
      }
      .ratelist {
        .list {
          table {
            text-align:center;
            margin-top:0;
          }
        }

        .listheader {
          table {
            text-align:left;
            margin-top:0;
            th {
              font-weight:normal;
            }
          }
        }
        @include breakpoint(lt400) {
          td, th {
              padding:1em 0;
            text-align:center;
          }
        }
      }

      .buttons {
        width:440px;
        float:right;
        .var1 {
          margin-right:1em;
          background-color: rgba(138, 171, 140, 0.2);
          &:hover {
            background-color:$clr-lgreen;
          }
        }
        .var2 {
          background-color: rgba(138, 171, 140, 0.56);
          &:hover {
            background-color:$clr-green;
          }
        }
      }

      @include breakpoint(lt400) {
        .buttons {
          width:100%;
          margin-top:5em;
        }
      }
    }

    .infotext {
      text-align:center;
      font-size:1.4em;
      margin-top:2em;
      @include breakpoint(lt400) {
        font-size:13px;
      }
    }

    .buttons {
      text-align:right;
    }
  }

  .desktop {
    .slick-slider {
      position:static;
    }
    .slick-arrow.big.slick-prev {
      right:440px;
      @include breakpoint(lt1000) {
        right:335px;
      }
      @include breakpoint(lt768) {
        right:275px;
      }
    }
    .slick-arrow.big {
      bottom:0;
      width:55px;
      height:55px;
      @include breakpoint(lt1000) {
        width:35px;
        height:35px;
        bottom:5px;
      }

      .icon {
        font-weight:500;
      }
    }
    .slick-dots {
      right:141px;
      @include breakpoint(lt1000) {
        right:127px;
      }
      @include breakpoint(lt768) {
        right:100px;
      }
      bottom:3em;
      li {
        font-size:140px;
        @include breakpoint(lt1000) {
          font-size:75px;
        }
      }
    }
  }




  .mobile {
    display:none;

    .slick-dots {
      right:9.5em;
      bottom:-5em;
      li {
        font-size:10em;
      }
    }
    .slick-arrow.big {
      bottom:-6em;
    }
  }

  @include breakpoint(lt400) {
    .mobile {
      display:block;
    }
    .desktop {
      display:none;
    }
  }

  .roomdetail {
    padding:2em 0;
    border-top:1px solid $clr-green;
    .iconbar {
      width:100%;
      background:transparent;
      border:0;
      color:$clr-black;
      cursor:pointer;

      text-align:left;

      line-height:4em;

      .icon {
        width:1em;
        height:1em;
        margin-top:0.5em;
        // background:red;
        display:inline-block;
        font-size:2em;
      }

      .title {
        font-size:2.1em;
        line-height:2.1em;
        font-weight:700;
        padding-left:1em;
      }

    }

    .text {
      padding:0 1em 2.1em 1em;
      font-size:1.6em;
      min-height:5em;
      @include breakpoint(lt400) {
        font-size:13px;
      }
    }

    @include breakpoint(lt1000) {
      .text {
        font-size:14px!important;
        min-height:unset;
        padding-top:1em;
      }
      .iconbar {
        line-height:1;
        .title {
          line-height:1.3;
          font-size:14px;
        }
        .time, .price, .icon {
          line-height:1.3;
          font-size:12px;
        }

        .icon {
          margin-top:0;
        }
      }
      .buttons {
        position:static;
      }
    }

    @include breakpoint(lt400) {
      padding:4em 0;
      .col-4, .col-8 {
        flex:0 0 50%;
        max-width: 50%;
      }
      .rateswitch {
        .col-4 {
          display:none;
          &:first-child {
            display:block;
            flex:0 0 100%;
            max-width:100%;
          }
        }


      }


      .listheader {
        padding-left:0;
        table {
          font-size:12px;
        }
      }
      .list {
        table {
          font-size:12px;

          td:nth-child(2), td:nth-child(3) {
            display:none;
          }
        }
      }

      .adds {
        padding:2em 1em 1em 1em;
        font-size:14px!important;

        @include dashlist(-1em);
      }
    }
  }

  &.hide1 {
    .BN_1_null_DV_PREPAID, .rateplanswitch  {
      display:none;
    }
  }

}
