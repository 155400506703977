.ehot_freerooms_list {

  padding-bottom:2em;

  .room {
    padding:11em 24em 0 0;

    &:nth-child(2n) {
      padding:11em 0 0 24em;
    }

    @include breakpoint(lt1000) {
      padding:11em 10em 0 0em;
      &:nth-child(2n) {
        padding:11em 0 0 10em;
      }
    }

    @include breakpoint(lt400) {
      padding:11em 0 0 0em;
      &:nth-child(2n) {
        padding:11em 0 0 0em;
      }
    }

    .teaser {
      position:relative;

      .desc {
        position:relative;
        padding-right:6em;
        @include breakpoint(gt1000) {
          .minh {
            min-height: 28em;
          }
        }
        .iconbar {
          display:none;
        }
        .head {
          font-family:$alt_font_family;
          font-size:4.2em;
          margin:0;
        }

        .subhead {
          color:$clr-green;
          font-size:2.1em;
          margin-top:1em;
        }

        .dates {
          margin-top:2em;
          font-size:1.6em;
          font-weight:700;
          min-height:3.3em;
        }

        .controls {
          margin-top:4em;
          .btn {
            width:100%;
            margin-top:1em;
          }
        }

        .buttons {
          display:flex;
          text-align:right;
          margin-top:6em;
           @include breakpoint(lt1000) {
             margin-top:12em;
           }
          @include breakpoint(lt400) {
            margin-top:6em;
          }
          margin-right:-13em;
          .btn:first-child {
            margin-right:1em;
          }
        }

        .badge {
          > div {
            width:100%;
            padding:4.1em;
          }

          position:absolute;
          height:19em;
          width:19em;
          background-color:$clr-green;
          bottom:8em;
          right:-12em;
          text-align:center;
          display:table-cell;
          vertical-align:center;
          border-radius:100%;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -ms-flex-align: center;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;

          .title {
            color:$clr-black;
            font-size:1.4em;
            font-weight:700;
            text-transform:uppercase;
            line-height:1.7;
          }

          .rate {
            color:$clr-lgreen;
            font-size:2.8em;
            font-weight:700;
            font-family: 'Noto Serif', serif;

            &.better {
              color:$clr-white;
            }

            .suffix {
              font-size:80%;
              &.onrequest {
                font-size:60%;
              }
            }

            &.old {

              position: relative;

              &:before {
                position: absolute;
                content: "";
                left: 0;
                top: 50%;
                right: 0;
                border-top: 2px solid;
                border-color: $clr-black;

                -webkit-transform:rotate(-10deg);
                -moz-transform:rotate(-10deg);
                -ms-transform:rotate(-10deg);
                -o-transform:rotate(-10deg);
                transform:rotate(-10deg);
              }
            }
          }
        }
      }

      .content {
        width:38%;
        float:left;
        @include breakpoint(lt1000) {
          width:35%;
        }
        .oversized {
          width:130%;
          background:white;
          position:relative;
          z-index:1;
          padding:6em 7em 0 0;
          margin-top:11em;
        }

        h2 {
          color:$clr-green;
          font-size:2.5em;
          margin-bottom:1em;
        }
      }

      .images {
        width:60%;
        float:right;
        @include breakpoint(lt1000) {
          width:65%;
        }
        &.mobile {
          display:none;
        }
      }
    }

    .buttons {
      text-align:right;
      .var1 {
        margin-right:1em;
        background-color: rgba(138, 171, 140, 0.2);
        &:hover {
          background-color:$clr-lgreen;
        }
      }
      .var2 {
        background-color: rgba(138, 171, 140, 0.56);
        &:hover {
          background-color:$clr-green;
        }
      }
    }
  }
@include breakpoint(gt400) {

  .slick-slider {
    position:static;
  }
}
  .slick-arrow.big.slick-prev {
    right:35%;
  }

  .slick-dots {
    right:10%;
    @include breakpoint(lt1000) {
      right:8.5%;
    }
    bottom:3em;
    li {
      font-size:14em;
    }
  }

  @include breakpoint(lt400) {

    .room {
      padding:0!important;
      .teaser {

        .desc {
          padding-right:0;
          .buttons {
            margin-right:0;
          }
          .iconbar {
            display:block;
            .icon {
              font-size:22px;
            }
          }
          .subhead {
            font-size:14px;
          }

          .dates {
            font-size:14px;
            margin-top:1.2em;
          }

          .badge {
            bottom:50em;
            right:50%;
            width:144px;
            height:144px;
            margin-right:-72px;
            &.autopos {
              display:none;
            }
            .title {
              font-size:10px;
            }

            .rate {
              font-size:24px;
            }
          }
        }

        .content {
          width:100%;
          .oversized {
            width:100%;
            padding:0;
          }
        }

        .images {
          display:none;
          float:none;
          width:100%;
          .badge {
            display:none;
          }
          &.mobile {
            display:block;
            padding-bottom:10em;
            padding-top:4em;
            position:relative;
            .badge {
              display:flex;
              top:-4em!important;
              left:50%;
              margin-left:-12em;
            }
          }


        }
      }
    }

    .slick-dots {
      bottom:-8em;
      right:22%;
    }

    .slick-arrow.big {
      bottom:-9em;
      &.slick-prev {
        right:65%;
      }
    }

  }

}
