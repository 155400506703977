.btn {

  display:inline-block;
  color:$clr-black;
  cursor:pointer;
  // button with prefixed black line
  &.stroke {
    text-transform:uppercase;
    font-size:1.6em;
    line-height:1.6em;
    margin-top:2em;
    font-weight:700;
    padding-left:3em;

    .rel {
      position: relative;
      width: 100%;

      .hov {
        width: 100%;
        position: absolute;
        top: -12%;
        height: 0.8em;
        left: 0;
        background-color: $clr-white;
        display: none;
      }

      .label {
        position:relative;
      }

      &:hover {
        .hov {
          display: inline-block;
        }
      }
    }

    @include breakpoint(lt1000) {
      font-size:14px;
    }

    &::before {
      content:'';
      position:absolute;
      top:0.8em;
      left:0;
      line-height:1.6em;
      display:inline-block;
      margin-right:1em;
      vertical-align:middle;
      width:2em;
      border-bottom:1px solid $clr-grey;
    }
  }

  &.icon {
    text-transform:uppercase;
    display:flex;
    background:transparent;
    border:0;
    color:$clr-black;
    font-size:1.6em;
    @include breakpoint(lt1000) {
      font-size:14px;
    }

    text-transform:uppercase;
    padding:5px;
    margin-right:4em;
    float:left;

    &:last-child {
      margin-right:0;
    }

    .icon {

    //  background:red;
      display:inline-block;
      margin-right:1em;
    }

    &:hover {
      cursor:pointer;
      color:$clr-green;
    }
  }

  // centered / green background
  &.var2 {
      text-transform:uppercase;
      background-color:$clr-green;
      color:$clr-black;
      line-height: 3.4em;
      font-size:1.6em;
      text-align:center;
      font-weight:700;
      width:100%;
      max-width:260px;
    @include breakpoint(lt1000) {
      font-size:14px;
    }
  }

  // centered / light green background
  &.var1 {
      text-transform:uppercase;
      background-color:$clr-lgreen;
      color:$clr-black;
      line-height: 3.4em;
      font-size:1.6em;
      text-align:center;
      font-weight:700;
      width:100%;
      max-width:260px;
    @include breakpoint(lt1000) {
      font-size:14px;
    }
  }

  .btn:visited {
    color:$clr-black;
  }




}

.buttons {
  display:flex;
  .btn {
    margin-left:0!important;
    margin-right:1em;
    &:last-child {
      margin-right:0em;
    }
  }
}

// TODO: Search better places for the following

.subline {
  a {
    font-size:1.6em;
    text-transform:uppercase;
    text-decoration:underline;
  }
}

.spinner {
  display: inline-block;
  position: relative;
  margin:1em;
  width: 64px;
  height: 64px;
}
.spinner div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.spinner div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes spinner {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}