.ma_1 {
  .headline {
    padding: 0 8.5%;
  }
}

.headline {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  &.small {
    margin-bottom: 1.8em;
    span {
      font-size: 2em;
      font-weight: 500;
    }
  }
  &.big {
    margin-bottom: -1.4em;
    span {
      font-size: 2.5em;
      font-weight: 700;
    }
  }
  &.right {
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 3em;
  }
  .first {
    color: $clr-green;
    &.black {
      color: $clr-black;
    }
  }
  .second {
    color: $clr-black;
    &.green {
      color: $clr-green;
    }
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 0.8em solid transparent;
    border-right: 0.8em solid transparent;
    border-top: 2em solid $clr-green;
  }
  .arrow-up {
    width: 0;
    height: 0;
    border-left: 0.8em solid transparent;
    border-right: 0.8em solid transparent;
    border-bottom: 2em solid $clr-black;
    margin-right: 0.5em;
  }
  .rounds {
    max-width: 30em;
    > img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}


@include breakpoint(lt1000) {
  .ma_1 {
    .headline {
      padding: 0;
    }
  }
}

@include breakpoint(lt400) {
  .headline {
    &.small {
      margin-bottom: 1.3em;
      span {
        font-size: 3em;
      }
    }
    &.big {
      span {
        font-size: 3.5em;
      }
    }
    &.right {
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10em;
    }
    .rounds {
      display: none;
    }
  }
}

