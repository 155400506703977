.ehot_quickrequest {
  z-index:11;
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  color:$clr-black;
  display:none;
  padding:0 4em;

  .alt_bg {
    background-color:$clr-green;
    height:100%;
  }
}

#std_quickrequest_2017 {
  margin:0!important;
  width:100%!important;
  max-width:100%!important;
}