.ce_facts {

  // green background
  .blend {
    width:100%;
    position:absolute;
    top:8.3em;
    left:0;
    height:45em;
    z-index:-1;

     @include breakpoint(lt400) {
       height:310px!important;
       top:7em;
     }
  }

  h2 {
    position:absolute;
    top:0;
    left:0;
    margin:0;
    z-index:2;
    max-width:20em;
    font-size:6em;
    text-transform:none;
    font-family: 'Noto Serif', serif;
  }

  .fact {
    position:relative;
    margin-top:11.5em;
    padding:0 2%;

    @include breakpoint(lt1000) {
      margin-top:16em;
    }

    .image {
      position:relative;
      picture {
        display:block;
        width:100%;
      }
      img {
        display:block;
      }
    }

    .content {
      .facts_number {
        font-size:10em;
        line-height:1;
        color:white;
        font-family:$out_font_family;
        text-shadow:
                -1px -1px 0 $clr-green,
                1px -1px 0 $clr-green,
                -1px 1px 0 $clr-green,
                1px 1px 0 $clr-green;
      }

      &.pre {

        .facts_number {
          color:$clr-lgreen;
        }
      }

      .facts_text {
        position:relative;
        padding-left:4em;
        padding-right:4em;
        min-height:5em;
        .catsata {
          position:absolute;
          top:1.5em;
          left:0;
          width:3em;
          border-bottom:1px solid $clr-grey;
          @include breakpoint(lt1000) {
            top:2em;
          }
        }
      }

      &.pre {
        display:none;
        .facts_text {
          padding-bottom:1em;
          p {
            min-height:60px;

          }

        }
      }

      &.post {
        display:block;
        .facts_text {
          margin-top:1em;
          p {
            margin:0;
          }
        }
      }
    }
  }

  .auto_slide {
    padding-top:16em;
    @include breakpoint(lt1000) {
      padding-left:10em;
      padding-right:10em;
    }
    width:100.85%;
    margin-left:-0.5%;
  }

  .slick-slide, .slide {


    &:nth-child(2n) {
      .fact {
        .content {
          &.pre {
            display:block;
          }

          &.post {
            display:none;
          }
        }

        margin-top:0;
      }
    }
  }

  .headline {
    padding:2em;
    .slick-pagination {
      font-size:12px;

      @include breakpoint(gt1000) {
        display:none;
      }
    }
  }

  .slick-arrow {
    top:45%;
    &.big {
      border-color:$clr-black;
      color:$clr-black;
      width:5em;
      height:5em;
      &:hover {
        background-color:none;
        border-color:white;
        color:$clr-black;

      }
      @include breakpoint(lt400) {
        width:30px;
        height:30px;

        .icon {
          font-size:30px;
          &:hover {
            border-radius:100%;
            background:white!important;
            border-color:white!important;
            color:black!important;
          }
        }
      }


    }
    &.big.slick-prev {
      left:0px;
      right:unset;
    }

    &.big.slick-next {
      right: 0px;
      left: unset;
    }
  }

  @include breakpoint(lt400) {

    h2 {
      margin-top:0;
      margin-bottom:0;
    }

    .auto_slide {
      font-size:1.6em;

        padding-top:10em;
        padding-left:6em;
        padding-right:6em;

    }
  }

}