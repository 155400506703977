.slick-arrow.big {
  position:absolute;
  bottom:0px;
  right:0px;
  background-color:transparent;
  border-radius:100%;
  border:0px solid transparent;
  padding:0;
  margin:0;
  color:$clr-green;
  z-index:2;
  cursor:pointer;
  width:5em;
  height:5em;

  .icon {
    font-size:5em;
    @include breakpoint(lt400) {
      font-weight:100;
    }
    position:relative;
    font-weight:500;
    border-radius:100%;
    &:hover {
      color:$clr-white;
      background-color:$clr-green;
      font-weight: 700!important;
    }
  }

  &.slick-next {
    .icon {
      right:1px;
    }
  }
  &.slick-prev {
    right:56em;
    .icon {
      left:1px;
    }
    @include breakpoint(lt1000) {
      right:40em;
    }

    @include breakpoint(lt400) {
      right:31em;
    }
  }
}

.slick-arrow.slick-disabled {
  pointer-events: none;
  opacity:0.5;
}

.slick-dots {
  list-style:none inside none;
  position:absolute;
  bottom:0.7em;
  right:11.5em;
  z-index:10;
  @include breakpoint(lt1000) {
    right:6.5em;
  }
  li {
    display:none;
    color:white;
    font-family:$out_font_family;
    text-shadow:
            -1px -1px 0 $clr-green,
            1px -1px 0 $clr-green,
            -1px 1px 0 $clr-green,
            1px 1px 0 $clr-green;
    font-size:9em;
    &.slick-active {
      display:block;
    }
  }

}

.slick-slide {
  height:auto;
}
