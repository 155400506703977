footer {
  .logo {
    margin: 15em 0 10em 0;
  }

  a {
    color:$clr-black;
    &:hover {
      color:$clr-green;
    }
  }

  .powered_by {
    font-size: 1.4em;
  }

  .subbar {
    .bn-icon--powered-by {
      font-size: 1em;

      @include breakpoint(lt1000) {
        font-size:14px;
      }
    }

    .legalbar {

    }

    .col-1,
    .col-8 {
      flex: unset !important;
    }

    .col-8 {
      display: flex;
      align-items: center;
    }

    .poweredbar {
      text-align:right;

      .dna-powered-link {
        font-size: 1em;
        > a {
          display: block;
          width: 10em;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    @include breakpoint(lt1367) {
      .poweredbar {
        .dna-powered-link {
          > a {
            width: 12em;
          }
        }
      }
    }

    @include breakpoint(lt1000) {
      .poweredbar {
        margin-top: 4em;

        .dna-powered-link {
          > a {
            width: 15em;
          }
        }
      }
    }

    @include breakpoint(lt768) {
      .poweredbar {
        .dna-powered-link {
          > a {
            width: 19em;
          }
        }
      }
    }
  }

  .footercontent {
    position:relative;
    .ce_buttonmenu a {
      width:100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 1em;
      align-items: center;
    }

    @include breakpoint(gt1000) {
      .col_icons_align_left {
        .ce_buttonmenu {
          gap: 4em;
          align-items: center;
          flex-wrap: nowrap;
        }
      }
    }
      .col_icons_align_right {
        position:absolute;
        right:0;
        top:-30%;
        right:-14.5%;

        @include breakpoint(lt1000) {
          top:unset;
          bottom:0;
          right:0;
          max-width:130px;
          .ce_buttonmenu.align_right a {
            display:inline-block;
            width:unset;
            padding:0;
          }
          .icon {
            text-align:right!important;
            min-width:2em!important;
          }
        }

        @include breakpoint(lt400) {
            max-width:100%;
            width:100%;
            flex-wrap:nowrap;
        }

        .icon {
          min-width:3em;

          @include breakpoint(gt1000) {
            @include bigger(1.2);
          }
          @include breakpoint(lt400) {
            min-width:32px;
            padding:10px;
          }
          text-align:center;
        }
      }

    @include breakpoint(lt1000) {
      .col_add {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        margin-bottom: 4em;
      }

      .col_ratings {
        margin-top: 4em;
        display: flex;
        gap: 2em;
        justify-content: center;
        font-size:2.2em!important;
        flex-wrap: wrap;
      }

      .col_icons_align_right {
        flex:unset;
        flex-wrap:unset;
        text-align:center;
        position:static;
        display:flex;
        justify-content:center;
        max-width: 100%;
        margin-top: 4em;
        .ce_buttonmenu {
          flex-wrap: nowrap;
          font-size: 20px;
        }
      }

      .col_icons_align_left {
        .ce_buttonmenu {
          gap: 2em;
        }
      }

    }

    @include breakpoint(lt400) {
      .col_icons_align_right {
        .ce_buttonmenu {
          flex-wrap: wrap;
          font-size: 14px;
        }
      }
      .col_add {
        flex:0 0 100%;
        max-width:100%;
        text-align:center;
        margin-bottom:4em;
      }
    }
  }

  .row {
    margin-top: 6em;
    justify-content: space-between;
  }
}

.dna-creditsmenu {
  @include dashlist(-1em);
}

body .smts-widget {
  margin: 4em auto;
}

.dna-sitemap {

  ul {

    li {
      font-size:16px;
      line-height:2;
    }
    padding-left:30px;
  }
  @include dashlist(-1em);
}

@import 'elements/nav_seopages';
@import 'elements/nav_legal';
@import 'elements/nav_breadcrumb';
@import 'elements/nav_quick';
@import 'elements/dna_langmenu';
@import 'elements/ce';
@import 'elements/ce_buttonmenu';
@import 'elements/ce_gallery';
@import 'elements/ce_gallery_detail';
@import 'elements/ce_download_list';
@import 'elements/ce_facts';
@import 'elements/ce_topoffers';
@import 'elements/ce_newsinterface';
@import 'elements/ce_newsdetail';
@import 'elements/ce_news_list';
@import 'elements/ce_textpic';
@import 'elements/text_pic_box_v2';
@import 'elements/ce_socialwall';
@import 'elements/ce_address';
@import 'elements/ce_buttonmenu';
@import 'elements/ce_previews';
@import 'elements/ce_partner';
@import 'elements/ce_header';
@import 'elements/ce_text';
@import 'elements/ce_widgets';
@import 'elements/ce_accordeon';
@import 'elements/ce_imprint';
@import 'elements/ce_product';
@import 'elements/ce_headline';
@import 'elements/js_popup';
@import 'elements/ehot_quickrequest';
@import 'elements/ehot_rates_list';
@import 'elements/ehot_rooms_list';
@import 'elements/ehot_offer_list';
@import 'elements/ehot_offer_detail';
@import 'elements/ehot_beauty_list';
@import 'elements/ehot_freerooms_list';
@import 'elements/ehot_adds_list';
@import 'elements/ehot_weather';
@import 'elements/static_fixedcontact';

@import 'elements/shared/sliders';
@import 'elements/shared/filters';
@import 'elements/shared/buttons';
@import 'elements/shared/forms';

@include breakpoint(lt1700) {
  body {
    font-size: 55%;
  }
}

@include breakpoint(lt1400) {
  body {
    font-size: 50%;
  }
}

@include breakpoint(lt1250) {
  body {
    font-size: 45%;
  }
}

@include breakpoint(lt1050) {
  body {
    font-size: 40%;
  }

  .ehot_quickrequest {
    display: none !important;
  }
}

@include breakpoint(lt1000) {
  .ma_1, .ma_3 {
    max-width: $size-1;
    width: $size-1p;
  }

  .ma_2, footer.ma_3 {
    max-width: 505px;
    width: 72%;
  }
  .row {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  footer .footercontent .ce_buttonmenu a {
    width: fit-content;
  }
}

@include breakpoint(lt400) {
  body {
    font-size: 30%;
  }

  .ma_1, .ma_2, .ma_3 {
    max-width: 100%;
    width: 100%;
  }

  footer.ma_3 {
    width: 75%;
  }
}
