header {
  .ce_textpic {
    max-width: 100%;
  }
}

.no_space_top {
  margin-top: 0 !important;
}

.ce_textpic {
  padding-bottom:2em;

  .textpic {
      .content {
        width:33.6%;

        @include breakpoint(lt400) {
          width:25%;
        }

        float:left;
        @include dashlist(-1em);
        .oversized {
          width:120%;
          background:white;
          position:relative;
          z-index:2;
          padding:6em 7em 6em 0;
          margin-top:14em;
          min-height:65em;
          margin-bottom:-10em;
          @include breakpoint(lt400) {
            margin-top:18em;
          }
          @include breakpoint(lt1000) {
            min-height:39em;
            width:150%;
          }

        }

        h2 {
          color:$clr-green;
          font-size:2.5em;
          margin-bottom:1em;
          @include breakpoint(lt1000) {
            font-size:17px;
          }
        }

        @include breakpoint(lt400) {
          .oversized {
          padding:3em 3em 6em 0;
          width:230%;
            @include breakpoint(lt400) {
              width:320%;
            }
          }
          h2 {
            font-size:3em;
          }
        }
      }

      .image {
        width:66.4%;
        float:right;
        picture {
          display:block;
          width:100%;
        }
      }
  }

  .slick-slider {
    position:static;
  }

  .slick-dots {
    bottom:-6em;
  }

  .slick-arrow.big {
    bottom:-8em;
  }

  @include breakpoint(lt1000) {
    .slick-dots {
      bottom:-3em;
    }

    .slick-arrow.big {
      bottom:-3em;
    }
  }

  @include breakpoint(lt400) {
    .slick-dots {
      bottom:-10em;
    }

    .slick-arrow.big {
      bottom:-8em;
    }
  }

}

.ce_text {
  @include dashlist(-1em);
  p:first-child {
    margin-top:1em!important;
  }

  .text {
    display: flex;
  }
  .dots {
    width: 7em;
    background: url(/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/layout/dots.svg) no-repeat;
    margin-top: 2em;
    margin-right: 2em;
    flex-shrink: 0;
  }
}

.text.readmore {

  .teaser {
    display:none;
  }
  .full {
    display:block;
  }

  &.stat0 {
    .teaser {
      display:none;
    }
    .full {
      display:block;
    }
  }

  &.stat1 {
    .teaser {
      display:none;
    }
    .full {
      display:block;
    }
  }

  &.stat2 {
    .teaser {
      display:block;
    }
    .full {
      display:none;
    }
  }

  a {
    color:$clr-black;
    &:hover {
      color:$clr-green;
    }
  }
}

.textpic, .text {
  a {
    text-decoration:underline;
    &.btn {
      text-decoration:none;
      @include breakpoint(lt1000) {
        margin-top:1.2em;
      }
    }
  }
}

@include breakpoint(lt1000) {

  .ce_text {
    .dots {
      display: none;
    }
  }

  .text.readmore {
    &.stat1 {
      .teaser {
        display:block;
      }
      .full {
        display:none;
      }
    }
  }

}

@include breakpoint(lt400) {
  .text {
    font-size:1.6em;
  }
  h2 {
    font-size:3.2em;
  }
}

.blend {
  .ce_textpic {
    width:845px;
    @include breakpoint(lt1000) {
      width:90%;
    }
    .textpic {
      .content {
        width:20%;

        .oversized {
          width:400%;
          padding:60px 130px;
          @include breakpoint(lt1000) {
            padding:30px 60px;
          }
          min-height:unset;
          margin-top:290px;

          .rel {
            .hov {
              background-color:$clr-lgreen;
            }
          }

          a:hover {
            color:$clr-black;
          }
        }

        h2 {
          color:$clr-black;
          font-size:22px;
          margin:0;
        }

        h3 {
          color:$clr-green;
          font-size:16px;
          font-weight:700;
        }

        .text {
          margin-top:2em;
          p, li {
            font-size:16px;
          }

          a {
            font-size:16px;
          }
        }
      }
      .image {
        width:80%;
      }

      @include breakpoint(lt1000) {
        .content, .image {
          width:100%;
          margin-bottom:0;
        }

        .content {
          .oversized {
            width:100%;
            margin-top:0;
          }
        }
      }
    }
  }
}
