.ce_news_list {

  .subline {
    a {
      font-size:1.6em;
      text-transform:uppercase;
      text-decoration:underline;
    }
  }

  .news {
    position:relative;
    padding:0 5px;

    .image {
      position:relative;
    }

    .content {

      .rel {
        .hov {
          background-color: $clr-lgreen;
        }
      }

      .btn.stroke {
        @include breakpoint(lt1000) {
          margin-top:1em;
        }
      }

      position:relative;
      padding:5em 7em;
      top:-12em;
      left:0;
      bottom:auto;
      background:$clr-white;
      width:80%;
      z-index:2;
      min-height:34em;

      .title {
        font-size:2.2em;
        font-weight:700;
      }

      .date {
        font-size:1.6em;
        margin-top:0.5em;
      }

      .text {
        p {
          margin-top:1em;
        }
      }

      @include breakpoint(lt1000) {
        padding: 2em 2em 2em 2em;
        p {
          font-size:14px;
        }
        .title {
          font-size: 16px;
        }
        .date {
          font-size: 14px;
        }
      }

      @include breakpoint(lt400) {
        padding: 5em 7em 1em 0em;
      }

      &.pre {
        display:none;
        .facts_text {
          margin-bottom:1em;
        }
      }

      &.post {
        display:block;
        .facts_text {
          margin-top:1em;
        }
      }
    }
    @include breakpoint(gt400) {
      &.pl {
        padding-left: 17%;
        padding-right: 0;
      }
      &.pr {
        padding-left: 0;
        padding-right: 17%;
      }


    }
  }

  .newscol {

    width:50%;
    float:left;

    @include breakpoint(lt400) {
      width:100%;
    }


    &:nth-child(odd) {
      .pl {
        padding-right: 10px;
      }
    }

    &:nth-child(even) {
      .pr {
        padding-left: 10px;
      }
    }

    &:nth-child(2n) {
      @include breakpoint(gt400) {
        .news {

          .content {

            bottom: 0;
            top: auto;
            right: auto;
            left: 20%;

            &.pre {
              display: block;
            }

            &.post {
              display: none;
            }
          }

          .image {
            position:relative;
            top:-15em;
          }
        }
      }
    }
  }

  // force relative arrow positions
  .slick-slider, .slick-track, .slick-list {
    position:static;
  }

  .slick-list {
    min-height:85em;
    position:static;
  }

  .auto_slide {
    max-width:80%;
    margin:auto;
  }

  @include breakpoint(lt400) {

    .news {
      position:relative;
      margin-top:0;
      padding:0;

      .image {
        position:relative;
        text-align:right;
      }
    }

    .newscol {
      &:nth-child(2n) {
        .news {
          .image {
            text-align:left;
          }
          .content {
            padding: 5em 0em 1em 7em;
            float:right;
          }
        }
      }
    }

  }

}