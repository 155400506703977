// TODO: Refactor names from reviewlinks to logolinks / mask/scss/etc.
.ce_partner{

  margin:0 0 5em 0;

  @include breakpoint(lt400) {

    margin:0 0 0 0;
  }

  .show_target {
    cursor:pointer;
    font-size:1.4em;
    @include breakpoint(lt1000) {
      font-size:12px;
    }
    @include breakpoint(lt400) {
      text-align:center;
    }
    font-weight:700;
    text-transform:uppercase;

    span {
      display:inline-block;
      margin-left:0.5em;
    }

    margin-bottom:2em;

    &.open {
      .icon {
        @include rotate(180deg);
      }
    }

    &:hover {
      color:$clr-green;
    }
  }
.tar {
  display:flex;
  align-items: center;
  justify-content: center;

  .slide {
    padding:0 4em;
    &:first-child {
      padding-left:0;
    }
    &:last-child {
      padding-right:0;
    }
    .image {

    }

    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);

      &:hover {
        -webkit-filter: unset;
        filter: unset;
      }
    }


  }

  @include breakpoint(lt1000) {
      flex-direction:row;
    flex-wrap:wrap;
    flex-flow:row wrap;
    align-content:flex-end;
    .slide {
      max-width:110px;
      margin:20px 0;
    }
  }
}



}