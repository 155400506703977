.ehot_adds_list {

  .additionals_filter {
    background-color: #fff;
    z-index: 1000;
  }

  border-bottom:1px solid $clr_green;

  h1 {
    margin-bottom:1em;
  }
  .beauty {
    padding:2em 0;
    border-top:1px solid $clr-green;


    @include breakpoint(lt1000) {
      padding:3em 0;
    }
    @include breakpoint(lt400) {
      padding-bottom:30px;
    }
    .iconbar {
      width:100%;
      background:transparent;
      border:0;
      color:$clr-black;
      cursor:pointer;
      position:relative;

      text-align:left;

      line-height:4em;

      .icon {
        width:1em;
        height:1em;
        margin-top:0.5em;
        // background:red;
        display:inline-block;
        font-size:2.2em;
        color:$clr-green;

        &.dna-icon--x {
          color:$clr-black;
        }
      }

      .title {
        font-size:2.1em;
        line-height:2.1em;
        font-weight:500;
        padding-left:1em;
      }

      .time {
        font-size:1.6em;
        line-height:3em;
        @include breakpoint(lt400) {
         position:absolute;
          left:15px;
          bottom:-15px;
          max-width:100px;
        }

      }

      .price {
        font-size:1.6em;
        line-height:3em;
        font-weight:bold;
        @include breakpoint(lt400) {
          position:absolute;
          left:110px;
          bottom:-15px;
          max-width:100px;
        }
      }

      .control {

        @include breakpoint(lt400) {

          position:absolute;
          right:15px;
          top:3px;
          .icon {
            font-size:18px;
          }
        }
      }
    }

    .text {
      padding:0 1em 2.1em 1em;
      font-size:1.6em;
      min-height:5em;
      @include dashlist(-1em)
    }


    .buttons {
      display: flex;
      justify-content: space-between;
      width:100%;
      position:absolute;
      bottom:0;
      left:0;
      .var1 {
        margin-right:1em;
        background-color: rgba(138, 171, 140, 0.2);
        &:hover {
          background-color:$clr-lgreen;
        }
      }
      .var2 {
        background-color: rgba(138, 171, 140, 0.56);
        &:hover {
          background-color:$clr-green;
        }
      }

      .added_alert {
        position: absolute;
        font-size: 10px;
        top: -4px;
        left: 50%;
        line-height: 1;
        transform: translate(-50%, -100%);
        width: 100%;
        color: green;
        pointer-events: none;
        opacity: 1;
        &.inactive {
          transition: opacity .5s;
          opacity: 0;
        }
      }
    }

    @include breakpoint(lt1000) {
      .text {
        font-size:14px;
        padding-top:2em;

      }
      .iconbar {
        line-height:1;
        .title {
          line-height:1.3;
          font-size:14px;
          font-weight:700;
        }
        .time, .price, .icon {
          line-height:1.3;
          font-size:12px;
        }
        .icon {
          margin-top:0;
          @include bigger(1.1);
        }
      }
    }

    @include breakpoint(lt400) {
      .buttons {
        position: static;
      }

      .text {
        min-height:unset;
      }
    }
  }

  .filter.beauty {
    flex-wrap: wrap;
    justify-content: space-evenly;
    .btn {
      flex: 1 0 21%;
      justify-content:center;
      margin-right:0;
    }
  }
}
