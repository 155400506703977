.ehot_rates_list {

  @include breakpoint(lt1000) {
    .filter .btn.icon {
      width:100%!important;
    }
  }
  .table {
    border-collapse:collapse;
  }

  .table th, .table td {
    border-top:1px solid $clr-lgreen;

  }

  .table tr {

    &:first-child, &.nobor {
        td, th {
          border-top:0;
        }
    }
  }

  .ratecontrol {
    padding:6em 0 3em;
    background-color:white;
    z-index:1;
    &.docked {
      border-bottom: 1px solid rgba(0,0,0,.26);
    }

    .rateswitch {
      text-align:center;
      .head {
        font-size:2.3em;
        font-weight:bold;
        @include breakpoint(lt400) {
          font-size:14px;
        }
      }

      .desc {
        font-size:1.6em;
        // TODO: Change to 1.5em as soon as rate conditions are ready
        margin-top:-1.1em;
        @include breakpoint(lt400) {
          font-size:14px;
        }
      }
    }

    .rateplanswitch {
      visibility:hidden;
    }

    .seasonswitch {
      .btn.act {
        color:$clr-green;
      }
    }

    @include breakpoint(gt1000) {

        .col-2 {
          flex:0 0 25.5%!important;
          max-width: 25.5%!important;
        }

        .col-6 {
          flex:0 0 41%!important;
          max-width: 41%!important;
        }

    }

    @include breakpoint(lt400) {


      .seasonswitch {
        .btn.icon {
          margin-right:0;
        }

      }
      .rateplanswitch {
        visibility:visible;
      }
      .rateswitch {
        .col-sm-4 {
          display:none;
          &:first-child {
            display:block;
            flex:0 0 100%;
            max-width:100%;
          }
        }


      }
    }
  }

  .roomcat {

    .image {
      img {
        min-width:unset;
      }
    }

    .room {
      border-top:1px solid $clr-lgreen;
      padding:5em 0;

      .rel {
        .hov {
          background-color: $clr-lgreen;
        }
      }

      @include dashlist(-1em);

      .desc {
        .head {
          font-family:$alt_font_family;
          font-size:3.5em;
          margin:0;
          @include breakpoint(lt400) {
            font-size:23px;
          }
        }

        .subhead {
          color:$clr-green;
          font-size:2.1em;
          @include breakpoint(lt400) {
            font-size:17px;
          }
        }

        .text {
          margin-top:4em;
          .teasertext {
            font-size:1.6em;
            max-height:6.4em;

            overflow:hidden;
            @include breakpoint(lt1000) {
              max-height:unset;
              p, li {
              font-size:14px;
              }
            }
            @include breakpoint(lt400) {
              font-size:14px;
              margin-top:2em;
            }
          }
        }

        @include breakpoint(lt1000) {
          margin-bottom:5em;
        }

      }

      .list {
        table {
          text-align:center;
          margin-top:0;
          th {
            font-weight:normal;
          }
        }
      }

      .listheader {
        padding-left:7em;
        table {
          text-align:center;
          margin-top:0;
          th {
            font-weight:normal;
          }
        }
      }

      @include breakpoint(lt1000) {
        .col-md-4 {
          flex:unset;
          max-width: unset;
        }
        .col-3, .col-5 {
          flex:0 0 50%;
          max-width: 50%;
        }
        .listheader {
          padding-left:0em;
          table {
            font-size:12px;
          }
        }
        .list {
          table {
            font-size:12px;
          }
        }

        table th, table td {
          padding-left:0;
          padding-right:0;
        }
        .rateconditions {
          display:none;
        }
      }

      @include breakpoint(lt400) {



        .listheader {
          padding-left:0;
          table {
            font-size:12px;
          }
        }
        .list {
          table {
            font-size:12px;

            td:nth-child(2), td:nth-child(3) {
              display:none;
            }
          }
        }

      }

      .infotext {
        font-size:1.6em;
        text-align:center;
        margin-top:2em;
        @include breakpoint(lt400) {
          font-size:14px;
        }
      }

      .buttons {

        &.mobile_only {
          display:none;
        }


        margin-top:4em;
        @include breakpoint(lt400) {
          margin-bottom:4em;
        }
        @include breakpoint(lt1000) {
          &.mobile_only {
            display:flex;
            width:100%;
          }
          &.desktop_only {
            display:none;
          }

          .btn {
            max-width:unset!important;
          }
        }
        .var1 {
          margin-right:1em;
          background-color: rgba(138, 171, 140, 0.2);
          &:hover {
            background-color:$clr-lgreen;
          }
        }
        .var2 {
          background-color: rgba(138, 171, 140, 0.56);
          &:hover {
            background-color:$clr-green;
          }
        }
      }
    }
  }

  &.hide1 {
      .BN_1_null_DV_PREPAID, .rateplanswitch {
        display:none;
      }
  }


}

