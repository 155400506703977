.ce_previews{

  h2 {
    margin-bottom:1em;

    @include breakpoint(lt1000) {
      width:100%!important;
      max-width:unset;
    }
  }

  .preview {
    position:relative;
    padding:0 1%;

    margin-top:7.1em;



    @include breakpoint(lt400) {
      margin-top:5em;
      padding:0;
      &:nth-child(even) {
        margin-right:90px;
      }
      &:nth-child(odd) {
        margin-left:90px;
      }
    }

    a.overlay {
      position:absolute;
      top:0;left:0;width:100%;height:100%;
      display:block;
      overflow:hidden;
      text-indent:-9999px;
      padding:0 5px;
    }
    .image {
      position:relative;
      img {
        display:block;
      }

      .hov {
        opacity:0.5;
        display:block;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color:$clr-green;
      }

      picture {
        display:block;
      }
    }

    .title {
      position:absolute;
      bottom:0em;
      padding:0.8em 1em 0 0;
      font-size:3em;
      @include breakpoint(lt1000) {
        font-size:17px;
      }
      color:$clr-green;
      background-color:$clr-white;
      z-index:1000;
      font-family:$alt_font_family;
      max-width:80%;
      pointer-events:none;
    }

    &:hover {
      .title {
        color:$clr-black;
      }

      .image {

        .hov {

          display:none;
        }
      }

    }


  }
.auto_slide {
  width:100.27%;
  margin-left:-0.25%;
}
  .slick-slide {
    &:nth-child(even) {
      .preview {
        margin-top:0em;
        .title {
          bottom: auto;
          top: 0em;
          padding: 0 1em 1em 0;
        }
      }
    }
  }


  @include breakpoint(lt400) {
    .preview:nth-child(odd) {
      .title {
        bottom: unset;
        top: 0em;
        padding: 0 1em 1em 0;
      }
    }
  }

}