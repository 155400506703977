.content {
  .ce {
    position:relative;
    margin-top:$contentmargin-top;

    &.ce_timedtext {
      margin-bottom:-10em;
    }

    iframe {
      overflow:hidden;
      border:none;
    }

    &.alt_bg, .alt_bg {
      background-color:$clr-lgreen;
    }

    &:nth-child(1) {
      margin-top:0;
    }

    p, ul, ol, table {
      margin-top:1em;
      font-size:1.6em;
      line-height:1.9em;

      ul, ol {
        font-size:unset;
      }
    }

    @include breakpoint(lt1000) {
      p, table {
        font-size: 14px;
      }
      margin-top:12em;
    }


    &.ehot {
      p, ul, ol {
        font-size:1em;
        line-height:1.7em;
        margin-top:0;
      }
    }

    ul, ol {
      list-style:unset;
      list-style-position:outside;
      margin:1em 2em;
    }


  }
  @include breakpoint(lt400) {
    .ce_header + .ce {
      margin-top:4em;
    }
  }
}

.image.lightbox {
  position:relative;

  .icon {
    width:30px;
    height:30px;
    color:$clr-white;
    position:absolute;
    top:5px;
    left:5px;
    font-size:2em;
    pointer-events:none;
    @include breakpoint(lt1000) {
      font-size:20px;
    }
  }


}