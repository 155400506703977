[class^="dna-icon--"], [class*=" dna-icon--"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'DNA' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mfp-arrow {
  font-family: 'DNA' !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:3em;
  color:white;
  border:0;
  &:hover {
    color:$clr-lgreen;
  }
  @include breakpoint(lt1000) {
    font-size:8em;
  }
}

.mfp-arrow-left:before, .mfp-arrow-left:after {
  content: "\e91a";
  border:0;
}
.mfp-arrow-right:before, .mfp-arrow-right:after {
  content: "\e920";
  border:0;
}

  /*
  .dna-icon--grid:before {
    content: "\e901";
  }
  .dna-icon--offers:before, .dna-icon--offercat1825:before {
    content: "\e902";
  }
  .dna-icon--bads:before, .dna-icon--add1456:before {
    content: "\e903";
  }
  .dna-icon--gallery:before {
    content: "\e904";
  }
  .dna-icon--doubleroom:before {
    content: "\e905";
  }
  .dna-icon--download:before {
    content: "\e906";
  }
  .dna-icon--singleroom:before {
    content: "\e907";
  }
  .dna-icon--email:before {
    content: "\e908";
  }
  .dna-icon--facebook:before {
    content: "\e909";
  }
  .dna-icon--familiensuite:before {
    content: "\e90c";
  }
  .dna-icon--faceadd:before {
    content: "\e90d";
  }
  .dna-icon--voucher:before {
    content: "\e90e";
  }
  .dna-icon--holidaycheck:before {
    content: "\e90f";
  }
  .dna-icon--hotel:before {
    content: "\e910";
  }
  .dna-icon--instagram:before {
    content: "\e911";
  }
  .dna-icon--bodyadd:before, .dna-icon--add1455:before {
    content: "\e912";
  }
  .dna-icon--cosmetics:before {
    content: "\e913";
  }
  .dna-icon--cooking:before {
    content: "\e914";
  }
  .dna-icon--position .path1:before {
    content: "\e917";
    color: rgb(0, 0, 1);
  }
  .dna-icon--position .path2:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .dna-icon--news:before {
    content: "\e920";
  }
  .dna-icon--arrow-down-small:before {
    content: "\e926";
  }
  .dna-icon--lastminutes:before, .dna-icon--offercat201:before  {
    content: "\e927";
  }
  .dna-icon--summer:before, .dna-icon--offercat1836:before, .dna-icon--offercat1937:before  {
    content: "\e928";
  }
  .dna-icon--suite:before {
    content: "\e929";
  }
  .dna-icon--phone:before {
    content: "\e92a";
  }

  .dna-icon--trehs:before, .dna-icon--add1459:before {
    content: "\e940";
  }
  .dna-icon--blog:before {
    content: "\e941";
  }
  .dna-icon--signet:before {
    content: "\e942";
  }
  .dna-icon--videos:before {
    content: "\e943";
  }
  .dna-icon--webcam:before {
    content: "\e944";
  }
  .dna-icon--vine:before, .dna-icon--offercat1839:before, .dna-icon--offercat1940:before {
    content: "\e945";
  }
  .dna-icon--wellness:before, .dna-icon--offercat1842:before, .dna-icon--offercat1939:before {
    content: "\e946";
  }
  .dna-icon--weather:before {
    content: "\e947";
  }
  .dna-icon--winter:before, .dna-icon--offercat1826:before, .dna-icon--offercat1938:before {
    content: "\e948";
  }
  .dna-icon--youtube:before {
    content: "\e94f";
  }
  .dna-icon--arrow-right:before {
    content: "\e915";
  }
  .dna-icon--arrow-left:before {
    content: "\e916";
  }

   new */

.dna-icon--alle:before {
  content: "\e900";
}
.dna-icon--angebote:before, .dna-icon--offercat1825:before {
  content: "\e901";
}
.dna-icon--baeder:before, .dna-icon--add1456:before {
  content: "\e902";
}
.dna-icon--bildgalerie:before {
  content: "\e903";
}
.dna-icon--doppelzimmer:before {
  content: "\e904";
}
.dna-icon--download:before {
  content: "\e905";
}
.dna-icon--einzelzimmer:before {
  content: "\e906";
}
.dna-icon--e-mail:before {
  content: "\e907";
}
.dna-icon--facebook:before {
  content: "\e908";
}
.dna-icon--facebook_daumen:before {
  content: "\e909";
}
.dna-icon--facebook_kommentar:before {
  content: "\e90a";
}
.dna-icon--familiensuite:before {
  content: "\e90b";
}
.dna-icon--gesichtsbehandlungen:before, .dna-icon--add1457:before {
  content: "\e90c";
}
.dna-icon--gutschein:before {
  content: "\e90d";
}
.dna-icon--holidaycheck:before {
  content: "\e90e";
}
.dna-icon--hotel:before {
  content: "\e90f";
}
.dna-icon--instagram:before {
  content: "\e910";
}
.dna-icon--koerperbehandlungen:before, .dna-icon--add1455:before {
  content: "\e911";
}
.dna-icon--kosmetik:before, .dna-icon--add1458:before {
  content: "\e912";
}
.dna-icon--kulinarik:before {
  content: "\e913";
}
.dna-icon--lage:before {
  content: "\e914";
}
.dna-icon--lupe:before {
  content: "\e915";
}
.dna-icon--massagen:before, .dna-icon--add1454:before {
  content: "\e916";
}
.dna-icon--menu_mobile:before {
  content: "\e917";
}
.dna-icon--neuigkeiten:before {
  content: "\e918";
}
.dna-icon--peeling:before {
  content: "\e919";
}
.dna-icon--pfeil_links:before, .mfp-arrow-left:before {
  content: "\e91a";
}
.dna-icon--pfeil_links_hover .path1:before {
  content: "\e91b";
}
.dna-icon--pfeil_links_hover .path2:before {
  content: "\e91c";
}
.dna-icon--pfeil_links_hover .path3:before {
  content: "\e91d";
}
.dna-icon--pfeil_oben:before {
  content: "\e91e";
}
.dna-icon--pfeil_oebn_klein:before {
  content: "\e91f";
}
.dna-icon--pfeil_rechts:before, .mfp-arrow-right:after {
  content: "\e920";
}
.dna-icon--pfeil_unten:before {
  content: "\e924";
}
.dna-icon--pfeil_unten_klein:before {
  content: "\e925";
}
.dna-icon--plus:before {
  content: "\e926";
}
.dna-icon--restplatzboerse:before, .dna-icon--offercat201:before {
  content: "\e927";
}
.dna-icon--sommer:before, .dna-icon--offercat1836:before, .dna-icon--offercat1937:before {
  content: "\e928";
}
.dna-icon--suite:before {
  content: "\e929";
}
.dna-icon--telefon:before {
  content: "\e92a";
}
.dna-icon--tirpadvisor:before {
  content: "\e92b";
}
.dna-icon--trehs:before, .dna-icon--add1459:before {
  content: "\e92c";
}
.dna-icon--valserhof_blog:before {
  content: "\e92d";
}
.dna-icon--valserhof_signet:before {
  content: "\e92e";
}
.dna-icon--videos:before {
  content: "\e92f";
}
.dna-icon--webcam:before {
  content: "\e930";
}
.dna-icon--wein:before, .dna-icon--offercat1839:before, .dna-icon--offercat1940:before  {
  content: "\e931";
}
.dna-icon--wellness:before, .dna-icon--offercat1842:before, .dna-icon--offercat1939:before {
  content: "\e932";
}
.dna-icon--wetter:before {
  content: "\e933";
}
.dna-icon--whatsapp:before {
  content: "\e934";
  zoom:1.4;
  -moz-transform:scale(1.4);
  -moz-transform-origin:50% 100%;
  display:inline-block;
  position:relative;
  left:-3px;
}
.dna-icon--winter:before, .dna-icon--offercat1826:before, .dna-icon--offercat1938:before {
  content: "\e935";
}
.dna-icon--wohlfuehlpakete:before, .dna-icon--add1453:before {
  content: "\e936";
}
.dna-icon--x:before {
  content: "\e937";
}
.dna-icon--x_mobile:before {
  content: "\e938";
}
.dna-icon--youtube:before {
  content: "\e939";
}
