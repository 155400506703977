.ehot_offer_list {
  @include breakpoint(gt400) {
    h1 {
      margin-left: 8.5%;
    }
  }

  .offercol.active {

    .offer {
      background-color: transparent;

      .rel {
        .hov {
          background-color: $clr-lgreen;
        }
      }

    }

  }

  .offercol {

    .offer {

      .btn.icon {
        &:hover {
          cursor:auto;
          color:$black;
        }
      }

    }

  }

  @include breakpoint(lt1000) {
    .offercol {
      &.modd {
        .offer {
          background-color: $clr-lgreen !important;
        }
      }
      &.meven {
        .offer {
          background-color: $clr-white !important;
        }
      }
    }
  }


.offer {
  padding: 7em 20em 20em 14em;
  @include breakpoint(lt1700) {
    padding: 7em 20em 15em 14em;
  }

  margin-top: 6em;
  background-color: $clr-lgreen;

  .icon {
    font-size: 1.5em;
  }

  .btn.cat {
    position: absolute;
    top: 5em;
    right: 0.5em;
    margin-right: 0;
  }

  .content {
    padding-right: 12%;

    .title {
      font-size: 2.5em;
      font-weight: normal;
      min-height: 3em;
      @include breakpoint(lt1700) {
        min-height: 5em;
      }
      @include breakpoint(lt1400) {
        font-size: 17px;
      }
      @include breakpoint(lt1000) {
        min-height: unset;
      }
    }

    .periods {
      font-size: 1.6em;
      font-weight: 700;
      margin-top: 1em;

    }



    .teaser, .text, .periods, .btn.stroke {
      font-size: 1.6em;

      @include breakpoint(lt1400) {
        font-size: 14px;
      }
    }

    .teaser, .text, .periods {
      line-height:2;
      p {
        line-height:2;
      }
    }



    @include breakpoint(lt1400) {
      .btn.stroke {
        margin-top:0;
      }

    }

    .text {
      margin-top: 1em;
      min-height: 3em;
      br {
        display:none;
      }
      @include breakpoint(lt1700) {
        min-height: 6em;
      }

    }

  }

  .image {
    max-width: 400px;
    float: right;
    position: relative;
    display: table;
    right: -20em;


    .badge {
      position: absolute;
      height: 19em;
      width: 19em;
      background-color: $clr-green;
      top: -11em;
      right: 4em;
      text-align: center;
      display: table-cell;
      vertical-align: center;
      border-radius: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      font-weight: bold;
      padding: 10px;

      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-box-align: center;

      align-items: center;

      .title {
        color: $clr-black;
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        padding:0 0.5em;
        @include breakpoint(lt1000) {
          padding:0;
        }
      }

      .rate {
        color: $clr-white;
        font-size: 3em;
        font-family: 'Noto Serif', serif;
        display: block;
        .suffix {
          font-size: 80%;
          &.onrequest {
            font-size:60%;
          }
        }
      }
    }

  }
  @include breakpoint(lt1000) {
    .icon.cat {
      font-size: 16px;
      top: 3em;
      right: 0.2em;
    }

    .image {
      top:-3em;
    }
  }
  @include breakpoint(lt400) {
    padding: 0;
    .image {
      max-width: 100%;
      width: 100%;
      right: unset;
      top:0;
      .badge {
        font-size: 1.6em;
        right: 50%;
        top: -5em;
        margin-right: -9.5em;
      }
    }
    .content {
      padding: 9em 6em 8em 6em;

      .title {
        font-size: 17px;
        min-height: unset;
      }
      .periods, .teaser, .text {
        font-size: 14px;
      }
    }

    .icon.cat {
      font-size: 16px;
      top: 3em;
      right: 0.2em;
    }
  }
}

}
