
.js_popup {
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  height:0;
  display:block;
  padding:0 2% 0 2%;
  background-color: rgba(#000, .7);

  .dna-icon--valserhof_signet {
    display:none;
  }

  @include breakpoint(lt1000) {
    padding: 0 3% 0 3%;
  }
  @include breakpoint(lt400) {
    padding: 0 0 0 0;
  }
  z-index:1011;

  .blend {
    width:90%;
    background-color:rgb(138,171,140);
    background-color:rgba(138,171,140,0.95);
    @include breakpoint(lt400) {
      width:100%;
    }
    max-width:1640px;
    margin:auto;
    height:100%;
    position:relative;
    padding-bottom:2em;
    overflow:auto;
    @include breakpoint(lt1000) {
      overflow:overlay;
    }
    .menu {
      text-align:right;
      padding:5em 4em;
      min-height:3em;
      .icon {
        font-size:3em;
        cursor:pointer;
      }

      @include breakpoint(lt400) {
        padding:3em 4em;
      }
    }

    .ajax_content {

      .spinner {
        display:inline-block;
        display:none;
        margin:auto;
        margin-top:10em;
      }
    }

  }



  &.fullscreen {
    padding:0;
    .blend {
      width:100%;
      max-width:unset;
    }

  }

  @include breakpoint(gt1000) {
    .fullscreen {
      .blend {
        .menu {
          width:1024px;
          margin:auto;
          .icon {
            position:relative;
            top:71px;
          }
        }
      }
    }
  }

  &.exit {
    .blend {
      width:40%;
      overflow:unset;
      background:transparent;
      @include breakpoint(lt1000) {
        width:60%;
      }
      @include breakpoint(lt768) {
        width:96%;
      }
      min-width:300px;
      margin-top:5%;
      .ajax_content {
        background-color:$clr_green;
        color:$clr_black;
        padding:0em 6em 6em 6em;
        .dna-icon--valserhof_signet {
          font-size:6em;
          cursor:pointer;
          position:relative;
          top:-0.5em;
          display:block;
        }

        .teaser {
          font-size:1.6em;
          padding-bottom:2em;
        }

        .bn-form-newsletter .bn-form__button-container {
          text-align:center;
        }
      }

      .menu {
        min-height:0;
        width:100%;
        .icon {
          font-size:2em;
          cursor:pointer;
          position:relative;
          top:5em;
          @include breakpoint(gt768) {
            left:3.6em;
            top:3.6em;
          }
        }
        @include breakpoint(lt400) {
          margin-top:10em;
        }
      }
    }
  }

}
