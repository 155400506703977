.ce {
  &.ce_socialwall {

    margin-top:$contentmargin-top+8em;

    h2 {
      font-family: 'Noto Serif', serif;
      position:absolute;
      top:-2.5em;
      font-size:6em;
      background:$clr-white;
      max-width:45%;
      width:45%;
      @include breakpoint(lt400) {
        max-width:80%;
        width:80%;
      }
      text-transform:none;
      background:$clr-white;
    }

    .headline {
      text-align:right;
      line-height:9em;
      display: block;
      padding: 0;
      @include breakpoint(lt400) {
        min-height:65px;
      }
      a {
        text-transform:uppercase;
        font-size:1.5em;
        @include breakpoint(lt400) {
          font-size:12px;
          display:none;
        }
        color:$clr-black;
        text-decoration:underline;
        &:hover {
          color:$clr-green;
        }
      }
      margin-bottom:6em;
      margin-right:12em;
      @include breakpoint(lt400) {
        margin-bottom:0;
        margin-right:2em;
      }
    }
    #curator-feed-valserhof {
      margin:1em 12em;
      @include breakpoint(lt400) {
        margin:0em 8em;
      }
    }
    .subline {
        line-height:9em;
        a {
          text-transform:uppercase;
          font-size:1.5em;
          @include breakpoint(lt400) {
            font-size:12px;
          }
          text-decoration:none;
        }
      margin-left:12em;
      @include breakpoint(lt400) {
        margin-left:8em;
      }
    }

    .subsubline {
      display:none;
      @include breakpoint(lt400) {
        display:block;

        text-align:center;
        background:white;
        line-height:9em;

        a {
          text-transform:uppercase;
          color:$clr_green;

          @include breakpoint(lt400) {
            font-size:12px;
          }
        }
      }
    }

    .posts {

      img {
        display:inline-block;
        margin:0 5px;
      }
    }
  }
}


.crt-grid-post .crt-post-header .crt-social-icon {
   margin-bottom:0!important;
}
.crt-load-more-container {
  display:none!important;
}
@include breakpoint(lt400) {
  .crt-widget {
    padding-bottom: 0px;
  }
}


#t3-pid--85 .ce_socialwall {
  .headline {
    min-height:65px;
  }
  .headline a, .subsubline {
    display:none;
  }

}