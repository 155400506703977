.ce_download_list {

  h2 {
    margin-bottom:1em;
  }

  margin-top:5em!important;

  .download {
    padding:2em 0;
    border-top:1px solid $clr-green;
    &:last-child {
      border-bottom:1px solid $clr-green;
    }
    .iconbar {
      width:100%;
      background:transparent;
      border:0;
      color:$clr-black;
      cursor:pointer;

      text-align:left;

      line-height:4em;

      .icon {
        font-size:2.1em;
        margin-top:0.5em;
        // background:red;
        display:inline-block;
        color:$clr-green;
        @include breakpoint(lt1000) {
          @include bigger(1.5);
        }
      }

      .title {
        font-size:2.1em;
        line-height:2.1em;
        font-weight:500;
        padding-left:1em;
        @include breakpoint(lt1000) {
          font-size:14px;
        }
      }

      .time {
        font-size:1.6em;
        line-height:3em;

      }

      .price {
        font-size:1.6em;
        line-height:3em;
        font-weight:bold;
      }
    }



    a {
      color:$clr-black;
      &:hover {
        color:$clr-green;
      }
    }

    &:hover {
      .icon {
        color:$clr-black;
      }
    }

    .text {
      padding:0 2.1em 2.1em 2.1em;
    }


    .buttons {
      display: flex;
      justify-content: space-between;
      width:100%;
      position:absolute;
      bottom:0;
      left:0;
      .var1 {
        margin-right:1em;
      }
    }
  }

  .filter.beauty {
    flex-wrap: wrap;
    justify-content: space-evenly;
    .btn {
      flex: 1 0 21%;
      justify-content:center;
      margin-right:0;
    }
  }
}