.nav_seopages {
  width:100%;
  font-size:1.4em;
  @include breakpoint(lt1000) {
    font-size:12px;
  }
  color:#B0B0B0;
  text-align:center;
  a {
    color:#B0B0B0;
    &:hover {
      opacity:1;
      color:$clr-black;
    }
  }
}