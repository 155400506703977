@import '../../node_modules/bootstrap/scss/_mixins';
@import '../../node_modules/bootstrap/scss/bootstrap-grid';
// @import '../../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../../node_modules/bootstrap/scss/_utilities';
@import '../../node_modules/bootstrap/scss/_tables';
@import '../../node_modules/bootstrap/scss/_nav';
@import '../../node_modules/bootstrap/scss/_card';
@import '../../node_modules/bootstrap/scss/_transitions';
@import '../../node_modules/slick-carousel/slick/slick.scss';

/*
 * variables and mixins
 */
@import 'variables/variables';
@import 'mixins/mixins';

@font-face {
  font-family: 'DNA';
  font-display: auto;
  src:
          url('fonts/DNA.ttf?ig462g') format('truetype'),
          url('fonts/DNA.woff?ig462g') format('woff'),
          url('fonts/DNA.svg?ig462g#DNA') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import 'layout/elements/shared/icons';
/*
 * custom files
 */
@media screen {
  @import 'modules/modules';
  @import 'layout/footer';
  @import 'layout/main';
}

@font-face {
  font-family: 'LPPhilharmoniaOutline';
  src: url('fonts/39247C_0_0.eot');
  src: url('fonts/39247C_0_0.eot?#iefix') format('embedded-opentype'), url('fonts/39247C_0_0.woff2') format('woff2'), url('fonts/39247C_0_0.woff') format('woff'), url('fonts/39247C_0_0.ttf') format('truetype');
}
