.ce_address {
  font-size:1.6em;
  line-height:1.6;
  @include breakpoint(lt1000) {
    font-size:14px;
    margin-bottom:2em;
  }

  a {
    color:$clr-black;
    text-decoration:underline;
  }
}