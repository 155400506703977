.scrollmagic-pin-spacer {
  z-index: 1000;
}
.filter {
  margin:6em auto 4em auto;

  display: flex;
  align-items: center;
  justify-content: center;
  /* You can set flex-wrap and
     flex-direction individually */
  flex-direction: row;
  flex-wrap: wrap;
  /* Or do it all in one line
    with flex flow */
  flex-flow: row wrap;
  /* tweak where items line
     up on the row
     valid values are: flex-start,
     flex-end, space-between,
     space-around, stretch */
  align-content: flex-end;

  &.docked {
    border-bottom: 1px solid rgba(0,0,0,.26);
  }

  .filter_trigger {
    &.act {
      color:$clr-green;
    }
  }

  .btn.icon {
    margin:0 2em;
    padding:12px;

    &.current {
      display:none;
    }

    .icon {
      min-width:1.1em;
      font-size:1.4em;
      position:relative;
      top:-0.1em;
      @include breakpoint(gt1000) {
        @include bigger(1.2);
      }
      @include breakpoint(lt1000) {
        top:3px;
      }
    }
  }

  @include breakpoint(lt1000) {
    .btn.icon {
      display:none;

      text-align:left;
      padding:10px 0;
      margin:auto!important;
      width:90%;


      &.current {
        display:block;
        width:90%;
        border-top:1px solid $clr-green;
        border-bottom:1px solid $clr-green;
        margin:auto;
        padding:15px 0;
        color:$clr-green;
        text-align:left;
      }

      &:last-child {
        margin-right:unset;
      }
    }
  }

  //max-width:90%;
}


