.ce {
  &.ce_topoffers {

    .rel {
      position: relative;
      width: 100%;

      .hov {
        display: inline-block;
        width: 100%;
        position: absolute;
        top: -0.3em;
        height: 0.9em;
        left: 0;
        background-color: $clr-white;
        display: none;
      }

      .label {
        position:relative;
      }
    }

    @include breakpoint(lt400) {
      max-height:unset;
    }

    h2 {
      font-family: 'Noto Serif', serif;
      position:absolute;
      top:-2.5em;
      font-size:6em;
      background:$clr-white;
      max-width:25%;
      text-transform:none;
      background:$clr-white;

      @include breakpoint(lt1000) {
        max-width:45%;
      }

      @include breakpoint(lt400) {
        max-width:60%;
        line-height:1.5;
      }
    }

    .headline {
      text-align:right;
      line-height:9em;
      a {
        text-transform:uppercase;
        font-size:1.5em;
        @include breakpoint(lt1000) {
          font-size:12px
        }
        text-decoration:underline;
        letter-spacing:1px;
        color:$clr-black;
        &:hover {
          color:$clr-green;
        }
      }

      .slick-pagination {
        font-size:1.5em;
        @include breakpoint(lt1000) {
          font-size:12px
        }
        margin-left:3em;
      }

      margin-bottom:2em;
      padding:5em 3em 0 3em;

      @include breakpoint(lt400) {
        margin-bottom:1em;
        font-size:1.6em;
      }
    }

    .auto_slide {
      max-width:85%;
      margin:auto;
      position:static;

      picture {
        display:block;
      }

      @include breakpoint(lt400) {
          max-width:100%;
      }
    }

    .offer {
        padding:0 1em 0 1em;

        .content {
            padding-right:40%;
            line-height:1.5;
                .title {
                  font-size:2.5em;
                  min-height:3em;
                  @include breakpoint(lt1000) {
                    min-height:unset;
                  }
                  margin-bottom:0;
                }

                .periods {
                  font-size:1.6em;
                  @include breakpoint(lt1000) {
                    font-size:14px
                  }
                  font-weight:700;
                  margin-top:0.5em;
                  line-height:2;
                }

                .teaser, .text {
                  font-size:1.6em;
                  line-height:2;
                  p {
                    line-height:2;
                  }
                  @include breakpoint(lt1000) {
                    font-size:14px
                  }
                }

                .text {
                  margin-top:1em;
                  min-height:4.5em;

                  br {
                    display:none;
                  }
                }

                .btn.stroke {
                  margin-top:0.5em;
                  margin-bottom:1em;

                  &:hover {
                    .hov {
                      display:inline-block;
                    }
                  }
                }

        }

        .image {
          max-width:60%;
          min-height:224px;
          @include breakpoint(lt1000) {
            max-width:47%;
          }

          float:right;
          position:relative;
          display:table;

          .badge {
            position:absolute;
            height:19em;
            width:19em;
            background-color:$clr-green;
            top:-11em;
            right:4em;
            text-align:center;
            display:table-cell;
            vertical-align:center;
            border-radius:100%;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            padding: 10px;

            @include breakpoint(lt1000) {
              height:150px;
              width:150px;
              top:-18em;
            }

            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;

            align-items: center;

            > div {
              width:100%;
            }

            .title {
              color:$clr-black;
              font-size:1.2em;
              @include breakpoint(lt1000) {
                font-size:12px
              }
              text-transform:uppercase;
              display:block;
              font-weight:700;
              padding:0 0.5em;
              letter-spacing:1px;
            }

            .rate {
              color:$clr-white;
              font-size:2.8em;
              @include breakpoint(lt1000) {
                font-size:22px
              }
              font-family: 'Noto Serif', serif;
              display:block;
              letter-spacing:1px;
              .suffix {
                font-size:80%;
                &.onrequest {
                  font-size:60%;
                }
              }
            }
          }
        }

        @include breakpoint(lt400) {
          padding:0;
          .image {
            max-width:100%;
            width:100%;
            .badge {
              font-size:1.6em;
              right:50%;
              top:-5em;
              margin-right:-9.5em;
            }
          }
          .content {
            padding:0 6em 5em 6em ;
            font-size:1.6em;
          }
        }
    }

    .slick-arrow {
      position:absolute;
      top:50%;
      background:transparent;
      border-color:$clr-black;
      color:black;
      z-index:2;

      .icon {
        position:relative;
        &:hover {
          border-radius:100%;
          background:white!important;
          border-color:white!important;
          color:black!important;
        }
      }

      @include breakpoint(lt400) {
        width:30px;
        height:30px;

        .icon {
          font-size:30px;
        }
      }

      &:before {
        font-size:2em;
      }

      &.slick-prev {
        left:0;

      }

      &.slick-next {
        right:0;

      }

      @include breakpoint(lt400) {
        top:45%;
      }
    }



  }
}
