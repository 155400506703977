.ce_textpic_v2 {
  padding: 0 25em 0 15em;
  @include breakpoint(lt1400) {
    padding: 0 10em 0 0;
  }
  @include breakpoint(lt1000) {
    padding: 0;
  }
  .textpic_v2 {
    .content {
      padding-left: 45%;
      @include breakpoint(lt1000) {
        padding-left: 0;
        margin-bottom: 4em;
      }
      h2 {
        color:$clr-black;
        font-size:2.5em;
        margin-bottom:1em;
        @include breakpoint(lt1000) {
          font-size:17px;
        }
      }
      h3 {
        color:$clr-green;
        font-size:16px;
        font-weight:700;
      }
      .text_v2 {

      }
    }
    .textpic_v2__images {
      position: relative;
      min-height: 50em;
      @include breakpoint(lt768) {
        min-height: 40em;
      }
      .image {
        &.image_0 {
          position: absolute;
          top: 0;
          left: 0;
          width: 32%;
          @include breakpoint(lt768) {
            width: 48%;
          }
        }
        &.image_1 {
          position: absolute;
          top: 11em;
          left: calc(32% + 15px);
          width: 45%;
          @include breakpoint(lt768) {
            width: 48%;
            left: calc(48% + 15px);
          }
        }
      }
    }
  }
}
