#t3-pid--57 {
  .ce_gallery_detail {
    margin-top:0em!important;
    &:first-child {
      margin-top:4em!important;
    }
  }
}

.ce_gallery_detail {

    .pic {
      position: relative;

      img {
        max-width: 100%;
        max-height: 90%;
      }
      @include breakpoint(gt400) {
      &.t {
        padding-bottom: 6%;
      }
      &.tr {
        padding-bottom: 12%;
        padding-left: 9.3%;
      }
      &.tl {
        padding-bottom: 12%;
        padding-right: 9.3%;
      }

      &.b {
        padding-top: 8%
      }
      &.br {
        padding-top: 12%;
        padding-left: 9.3%;
      }
      &.bl {
        padding-top: 12%;
        padding-right: 9.3%;
      }

      }

      @include breakpoint(lt400) {
        &.col-4 {
          flex:0 0 100%!important;
          max-width: 100%!important;
        }
        &:nth-child(odd) {
          text-align:left;
        }
        &:nth-child(even) {
          text-align:right;
        }



        .image {
          display:inline-block;

          .icon {
            text-align:left;
          }
        }

        margin-bottom:3em;
      }
  }

  .subline {
    margin-top:8em;
    display:none;
  }
}