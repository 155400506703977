.nav_breadcrumb {
  margin-top:2em;
  display:none;
  a {
    color:$clr-black;
    &:hover {
      color:$clr-green;
    }
  }
}
.ce_header {
  @include breakpoint(gt1000) {
  .nav_breadcrumb {
    display:block!important;
    padding-top:2em;
    position:absolute;
    right:0;
  }
  }
}

.hideslide {
  .nav_breadcrumb {
    display:block;
  }
  .ce_header .nav_breadcrumb  {
    display:none!important;
  }
}


@include breakpoint(lt1000) {
  .nav_breadcrumb {
    display:none!important;
  }
}

header.fixed {
  .nav_breadcrumb {
    display:none;
  }
}


