.ce_header {

  + .ce {
    margin-top:10em;
  }

  position:relative;
  line-height:1;
  min-height:1px;
  .patch {
    position:absolute;
    bottom:1em;
    right:1em;

    &.patch--text {
      width: 26em;
      height: 26em;
      border: 1px solid rgba(#8AAB8C, .9);
      border-radius: 100%;
      background-color: rgba(#8AAB8C, .9);
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      .innerpatch {
        width: calc(100% - 14px);
        height: calc(100% - 14px);
        border: 1px solid rgba(#fff, .9);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3em;
        transform: rotate(10deg);
        .patch_title {
          color: #fff;
          text-transform: uppercase;
          text-align: center;
          margin: 1.5em 0;
          > span {
            font-size: 24px;
          }
        }

        .patch_subtitle {
          color: #000;
          text-align: center;
          margin: 1.5em 0;
          > span {
            font-size: 15px;
          }
        }
      }
    }

    @include breakpoint(lt1400) {
      &.patch--text {
        .innerpatch {
          .patch_title {
            margin: 1em 0;
            > span {
              font-size: 18px;
            }
          }

          .patch_subtitle {
            margin: 1em 0;
            > span {
              font-size: 14px;
            }
          }
        }
      }
    }
    @include breakpoint(lt1050) {
      &.patch--text {
        width: 180px;
        height: 180px;
      }
    }
    @include breakpoint(lt400) {
      bottom:0em;
      right:0em;
      width: 140px;
      height: 140px;

      &.patch--text {
        width: 140px;
        height: 140px;
        .innerpatch {
          .patch_title {
            > span {
              font-size: 14px;
            }
          }

          .patch_subtitle {
            > span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .zitat {
    position:relative;
      .content {
      background:$clr-white;
      font-size:6em;
      font-family:$alt_font_family;
      padding:1em 2em 2em 0;
      position:absolute;
      top:-2em;
      left:0;
      max-width:42%;
      max-height:4.8em;
      line-height:1.2;
      overflow:hidden;

        @include breakpoint(lt1250) {
          max-width: 60%;
        }

        @include breakpoint(lt1000) {
          max-height:4.6em;
          padding:1em 1em 1em 0;
          max-width: 70%;
        }


      }
  }

  .seasons {

    .slick-dots {
      margin:1em;
      position:absolute;
      left:0px;
      top:35%;
      bottom:unset;
      right:unset;
      z-index:0;
      list-style:none inside none;
      li {
        display:block;
        font-size:1em;
        border:1px solid white;
        border-radius:100%;
        background-color:transparent;
        color:white;
        width:10px;
        height:10px;
        margin-bottom:12px;
        @include breakpoint(lt400) {
          width:5px;
          height:5px;
          margin-bottom:10px;
        }
        button {
          border:1px solid white;
          border-radius:100%;
          background-color:transparent;
          color:white;
          width:14px;
          height:14px;
          text-indent:-9999px;
          overflow:hidden;
          cursor:pointer;
          display:none;
        }

        &.slick-active {
          background-color:white;

        }
      }
    }

  }

  .topnews {


    .rel {
      position: relative;
      width: 100%;

      .hov {
        width: 100%;
        position: absolute;
        top: -0.3em;
        height: 0.8em;
        left: 0;
        background-color: $clr-white;
        display: none;
      }

      .label {
        position:relative;
      }

      &:hover {
        .hov {
          display: inline-block;
        }
      }
    }

    position:relative;

    @include breakpoint(lt1000) {
      &.open {
        z-index:1;
        .content {
          left:0;
          padding: 3em;
          transform: translateY(50%);
          .header span {
            display:inline-block;
            padding:0;
            &:first-child {
              padding:2em 0 1em 0;
              font-size:15px;
            }
          }

          .icon:before {
            content: "\e938"!important;
            font-size:0.7em;
          }
        }

      }
    }

    .content {

      position: absolute;
      bottom: 12em;
      left: 5vw;
      background: $clr-green;
      background: rgba(138,171,140,0.95);
      color: $clr-black;
      width: 40%;
      padding: 4.2em 0.5em 4.2em 10em;

      @include breakpoint(lt1000) {
        width: 60%;
        left:7em;
        padding: 3em 0.5em 3em 8em;
      }

      @include breakpoint(lt1000) {
        bottom:6.7em;
        width:auto;
        left:5em;
        padding: 1em;
        font-size:1.8em;
        .icon {
          margin:0!important;
        }
        .icon:before {
            content: "\e918"!important;
        }

        .badge_content,
        .ccontent {
          .text {
            padding-right:0!important;
            margin-top:0!important;

            p {
              margin-top:0!important;
              max-height:6em;
              overflow:hidden;
              text-overflow:ellipsis;
            }
          }
        }

      }

      .header {
        cursor:pointer;
        font-size: 2.5em;
        text-transform: uppercase;


        span:first-child {
          @include truncator;
          display:inline-block;
          width:72%;
        }

        &.open {
          line-height:1.5;
          span:first-child {
            @include truncator;
            display:inline-block;
            width:80%;
            white-space: normal;
            text-overflow: unset;
          }
        }

        .icon {
          margin: 0 2em 0 0.5em;
          display: inline-block;
        }

        @include breakpoint(lt1000) {
          span, span:first-child  {
            display:none;
          }
        }
      }

      .badge_content,
      .ccontent {
        // display:none;
        .subtitle {
          color: $clr-white;
          font-size: 1.6em;
          margin-top: 1em;
        }

        .text {
          margin-top: 1.2em;
          > p {
            line-height: 1.5;
          }
          a {
            color: $clr-white !important;
          }
          a:hover {
            color: $clr-black !important;
          }
        }

        .btn {
          display: block;
          margin-top: 1em;
        }

        .subtitle, .text {
          padding-right: 5em;
        }
      }
    }
  }

  .blend {
    position:absolute;
    width:100%;
    height:100%;
    background-color:rgb(138,171,140);
    background-color:rgba(138,171,140,0.95);
    top:0;left:0;
    display:none;
    padding-bottom:10em;

    .menu {
      text-align:right;
      padding:5em 4em;
      min-height:3em;
      .icon {
        font-size:3em;
        display:none;
      }

      @include breakpoint(lt400) {
        padding:3em 4em;
      }
    }

    .ajax_content {

        .spinner {
          display:inline-block;
          display:none;
          margin:auto;
          margin-top:10em;
        }
    }

  }

}
