.ce_accordeon {
  &.no_space_top {
    margin-top: 4em;
  }

  .accordeon {
    padding:2em 0;
    border-top:1px solid $clr-green;
    &:last-child {
      border-bottom:1px solid $clr-green;
    }
    p, ul, ol {
      font-size:1em;
      @include breakpoint(lt1000) {
        font-size:14px;
      }
    }

    .iconbar {
      width:100%;
      background:transparent;
      border:0;
      color:$clr-black;
      cursor:pointer;

      text-align:left;

      line-height:4em;

      .icon {
        width:1em;
        height:1em;
        margin-top:0.5em;
        // background:red;
        display:inline-block;
        font-size:2em;
        color:$clr-green;

        @include breakpoint(lt1000) {
          font-size:14px;
          @include bigger(1.5);
        }

      }


      .title {
        font-size:2.1em;
        line-height:2.1em;
        font-weight:500;
        color: $clr-black;
        padding-left:1em;
        @include breakpoint(lt1000) {
          font-size:14px;
        }
      }


      &:hover{

        .icon {
          color: $clr-black;
        }
        .title {
          color:$clr-green;
        }
      }

      &.open {

        .icon {
          color: $clr-black;
        }
      }

    }

    .text {
      padding:0 1em 2.1em 1em;
      font-size:1.6em;
      min-height:5em;
      @include dashlist(-1em)
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      width:100%;
      position:absolute;
      bottom:0;
      left:0;
    }
  }

}