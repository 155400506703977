#std_newsletter_2017 {

.bn-form__button {
  color:$clr-black!important;
  letter-spacing:1px;
  font-weight:700;
  &:hover {
    color:$clr-black!important;
  }
}

.bn-form__grid .bn-form__block .bn-form__row .bn-form__column.bn-form__column--requiredFields {
  position:static;
  width:100%;
  padding-left:30px;
  font-size:10px;
}

.bn-form-newsletter .bn-form__group select,
.bn-form-newsletter .bn-form__group input {
  border-bottom:1px solid inherit;
}


}
#std_quickrequest_2017 {
  padding:0 10px;
.bn-form-quickrequest {
  padding:12px 0 12px 0;
}

  .bn-form__column--consent {
    .bn-form__checkbox:last-child {
      display:none;
    }
  }

.bn-iconBtn {
  border-radius:100%;
  background-color:white;
  border-color:transparent!important;
}

.bn-form__button {
  letter-spacing:1px;
  font-weight:700;
  color:$clr-black!important;
}
}

#std_request_2017 {
  .bn-form__button {
    letter-spacing:1px;
    font-weight:700;
    color:$clr-black!important;
    font-weight:700;
  }
}

.bn-form-quickrequest .bn-form__column--phone {
  display: block!important;
  visibility: inherit!important;
}


.bn-cookiebanner {
  position:fixed;
  top:35%;
  left:50%;
  width:536px;
  margin-left:-268px;
  background-color:$clr-green;
  background-color: rgba(138,171,140,0.95);
  -webkit-box-shadow: 0px 0px 107px -44px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 107px -44px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 107px -44px rgba(0,0,0,0.75);

  padding:51px 37px 30px 58px;


  @include breakpoint(lt400) {
    top:12%;
    width:300px;
    margin-left:-150px;
    padding:10px;
  }

  .bn-cookiebanner__wrap {
    text-align:center;
  }
  .bn-cookiebanner__text {
    width:100%;
    margin-bottom:40px;
    text-align:justify;
    font-size:15px;
    line-height:1.5;
    color:$clr-black;
    a {
      color:$clr-black;
      text-decoration:underline;
      &:hover {
        color:$clr-white;
      }
    }
  }
  .bn-cookiebanner__button {
    background-color:$clr-lgreen;
    color:$clr-black;
    letter-spacing:1px;
    line-height:44px;
    font-weight:700;
    font-size:16px;
    &:hover {
      background-color:#FFFFFF;
    }
  }
}

