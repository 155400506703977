.gallcats {
  background: #fff;
  z-index: 2;
}

.no_space_top {
  margin-top: 0 !important;
}

.ce_gallery {

  .mobile {
    display:none;
  }
  @include breakpoint(lt400) {
    .mobile {
      display:block;
    }
    .desktop {
      display:none;
    }
  }

  .auto_slide.mobile {

    .image:nth-child(odd) {
      margin-right:30%;
      margin-bottom:3em;
    }
    .image:nth-child(even) {
      margin-left:30%;
    }

    .slide.mobile_only {
      padding-right:6em;
    }
  }

  .subline {
    a {
      font-size:1.6em;
      text-transform:uppercase;
      text-decoration:underline;
      letter-spacing:1px;
      color:$clr-green;
      &:hover {
        color:$clr-black;
      }
    }
    max-width:83%;

    margin:auto;
    position:relative;
    top:-2em;
    @include breakpoint(lt1000) {
      max-width:93%;

    }
    @include breakpoint(lt400) {
      top:0;
      a {
        font-size:12px;
      }
    }
  }
  // to prevent container height change on slide and keep margins between arrows and active pic
  .slick-list {
    min-height:32vw;
    @include breakpoint(lt1700) {
      min-height:34vw;
    }

  }



  .js-mfp__item {
    display:block;
  }

  @include breakpoint(lt1000) {
    .slick-list {
    min-height:38vw;
    }
    max-width:100%;
  }

  @include breakpoint(gt400) {

  // hide inactive slides entirely
  .slick-slide {
    opacity:0;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
  }

  .slick-current, .slick-active {
    &.slick-slide {
      opacity:1;
    }
  }

  // all slides are scaled down
  .slide {
    .image {
        transform: scale(0.80);
        -webkit-transition: 1s ease-in-out;
        -moz-transition: 1s ease-in-out;
        -o-transition: 1s ease-in-out;
        transition: 1s ease-in-out;

        img {
          max-width:100%;
        }
    }

  }

  // except the current one
  .slick-current {
    .slide {
      .image {
          transform: scale(0.9);
          margin-top:35%;
          -webkit-transition: 1s ease-in-out;
          -moz-transition: 1s ease-in-out;
          -o-transition: 1s ease-in-out;
          transition: 1s ease-in-out;
        &:hover {
          transform: scale(1);
        }
      }
    }
  }

  .image:hover {
    transform: scale(1);
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    z-index:2;
  }


  }

  @include breakpoint(lt400) {
    .slick-arrow.big {
      bottom:-10em;
      &.slick-prev {
        right:220px;
      }


        width:30px;
        height:30px;

        .icon {
          font-size:30px;
        }

    }

    .slick-dots {
      bottom:-7em;
      right:64px;
    }
    .subline {
      padding-top:18em;
      text-align:center;
    }
  }

  @include breakpoint(lt1000) {
    .subline {
      a {
        font-size:12px;
      }
    }

    .image.lightbox {

      .icon {
        @include breakpoint(lt400) {
          font-size:18px;
        }
      }

    }

  }

}
