
.ce_product {

    margin-top:0!important;
    &.first_product {
      margin-top:$contentmargin-top!important;
    }

    padding:2em 0;
    border-top:1px solid $clr-green;
    &.last {
      border-bottom:1px solid $clr-green;
    }
    p, ul, ol {
      font-size:1em;
      @include breakpoint(lt1000) {
        font-size:14px;
      }
    }

    .iconbar {
      width:100%;
      background:transparent;
      border:0;
      color:$clr-black;
      cursor:pointer;

      text-align:left;

      line-height:4em;

      .icon {
        width:1em;
        height:1em;
        margin-top:0.5em;
        // background:red;
        display:inline-block;
        font-size:2em;
        color:$clr-green;

        @include breakpoint(lt1000) {
          font-size:14px;
          @include bigger(1.5);
        }

      }


      .title, .control {
        font-size:2.1em;
        line-height:2.1em;
        font-weight:500;
        color: $clr-black;
        @include breakpoint(lt1000) {
          font-size:14px;
        }
      }


      &.open {

        .icon {
          color: $clr-black;
        }
      }

    }

    .image {
      float:left;
      margin-right:5em;
      margin-bottom:2em;
      max-width:320px;

      @include breakpoint(lt400) {
        max-width:unset;
        margin-right:0em;
      }
    }


    .text {
      padding:0;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      width:100%;
      position:absolute;
      bottom:0;
      left:0;
    }
  }

