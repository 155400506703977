.nav_lang {
  &.mobile {
    .res:last-child {
      display:none;
    }
    display:none;
    li {
      display:inline-block;
      padding:1em 0 1em 8px;
      color:$clr-black;
      font-size:14px;
      font-weight:bold;
      a {
        color:$clr-black;
        &:hover {
          color:$clr-black;
        }
      }
      &.dna-langmenu__item--act {
        a {
          color:white;
        }
      }
    }
  }
  @include breakpoint(lt1000) {
    display:none;
    &.mobile {
      display:block!important;
    }
  }
}

ul.nav_main.lvl_1 li.nav_lang:after {
  content:unset;
  display:none;
}

.dna-langmenu {
    position:relative;

    li {
      display:none;
      &.dna-langmenu__item--inact {
        position:absolute;
        z-index:1011;
        height:20px;
        background:white;
        left:-5px;
        a {
          line-height:1;
          padding:0 5px;
        }
        &:nth-of-type(1) {
          bottom:5px;
        }
        &:nth-of-type(2) {
          bottom:5px;
        }
        &:nth-of-type(3) {
          bottom:-20px;
        }
      }

      a {
        font-size:1.7em;
        line-height:2em;
        color:$clr-black;
        font-weight:500;
      }

      ul.alt_langs {
        position: absolute;
        top: 3em;
        left: -10px;
        background: #fff;
        padding: 3em 1em 0;

        li {
          padding:0 0.7em;
          margin-right:0.2em;
          background:white;
          float:left;

          a:hover {
            color:$clr_green;
          }
        }
      }

      &.dna-langmenu__item--act {
        display:block;
        position:static;
        &:after {
          font-family: 'DNA' !important;
          content: "\e924";
          position:relative;
          right:-5px;
          top:-1px;
        }
      }
    }

  &:hover {
    li {
      display: block;
    }
  }

}

.fixed .dna-langmenu li ul.alt_langs {
  top: 3.5em;
  padding: 0 1em 0;
}
