.content {
.ce_imprint {
  p, ul, ol, table {
    font-size:16px;
    @include breakpoint(lt1000) {
      font-size:14px;
    }
    @include breakpoint(lt400) {
      font-size:12px;
    }
  }
}
}