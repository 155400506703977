.ce_buttonmenu {
  font-size:1.6em;

  @include breakpoint(lt1000) {
    font-size:14px;
  }

  display:flex;
  justify-content:center;
  flex-wrap: wrap;

  a {
    color:$clr-black;
    display:inline-block;
    width:25%;
    padding:5px;

    .icon {
      display:inline-block;
      margin-right:1em;
      min-width:30px;

    }





  }

  @include breakpoint(lt400) {
    a {
      text-align:center;
      .icon {
        margin-right: 0;
        font-size: 1.6em;
      }
    }

    &.auto_slide {
      display:block;
      justify-content:unset;
      flex-wrap: unset;
    }

  }

  .slick-current {
    a {
      .icon {
        transform: scale(1.3);
        -webkit-transition: 1s ease-in-out;
        -moz-transition: 1s ease-in-out;
        -o-transition: 1s ease-in-out;
        transition: 1s ease-in-out;
      }
    }
  }

  &.icons_only {
    .icon {
      margin-right:0;
    }
    .label {
      display:none;
    }
  }

  &.block {
    .icon {
      margin-right:0;
    }
  }

  &.inline {
    .icon {
      margin-right:0.5em;
      @include breakpoint(lt400) {
        margin-right:0em;
      }
      font-size:1.8em;
    }
    .label {
      position:relative;
      top:-0.4em;
    }
  }

  &.align_right {
    a {
      display:block;
      width:100%;
      @include breakpoint(lt400) {
        display:inline-block;
        width:unset;
      }

      text-align:right;
      padding-right:0;
    }
  }

  .slick-arrow.big {
    border-color:$clr-black;
    color:$clr-black;
    &.slick-prev {
      right:unset;
      left:-40px;
    }
    &.slick-next {
      right:-40px;
    }

    .icon {
      font-size:2.5em;
      margin-right:0;
    }

    @include breakpoint(lt400) {
      height:35px;
      width:35px;
      bottom:15px;
    }
  }
}