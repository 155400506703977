.ehot_weather {
  .text {
    b {
      display:block;
      margin:1em 0;
    }
    font-size:1.6em;
    @include breakpoint(lt1000) {
      font-size:14px;
    }
  }

  .image {
    img {
      min-width:unset;
    }
    margin-bottom:5em;
  }



}