.nav_main_wrapper {

  .mobile_calltoaction {
    a {
      font-size:4em!important;
      padding:0 10px;
      line-height:3em;
    }



  }

.nav_quick {
  font-size:1.4em;
  display:none;
  padding:0 0 4em 0!important;

  @include breakpoint(lt400) {
    padding:0 0 0 0!important;
  }

  a.quicklink {
    letter-spacing:unset;
    color:$clr-black;
    background-color:rgba(255, 255, 255, 0.2);
    display:inline-block;
    width:50%;
    &:hover {
      background-color:$clr-white;
    }
    border-bottom:2px solid $clr-green;
    &:nth-child(odd) {
      border-right:2px solid $clr-green;
    }

    &:hover {
      background-color:$clr-white;
    }

    .rel2 {
      position: relative;
      width: 100%;

      .hov {
        width: 100%;
        position: absolute;
        top: -0.3em;
        height: 0.8em;
        left: 0;
        background-color: $clr-lgreen;
        display: none;
      }

      .label {
        position:relative;
      }

      &:hover {
        .hov {
          display: inline-block;
        }
      }
    }
  }



  @include breakpoint(lt1000) {
    font-size:12px;
    display:block;
  }

  @include breakpoint(lt400) {
    a.quicklink {
      font-size: 12px!important;
    }

  }
}
ul.nav_main.lvl_1 li.nav_quick, ul.nav_main.lvl_1 li.mobile_calltoaction  {
  border-top:0px;
}
ul.nav_main.lvl_1 li.nav_quick:after, ul.nav_main.lvl_1 li.mobile_calltoaction:after {
  content:unset;
  display:none;
}


}
