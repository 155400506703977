.nav_legal {
  font-size:1.4em;

  a {
    color:$clr-black;
    &:hover {
      color:$clr-green;
    }
  }

  @include breakpoint(lt1000) {
    font-size:12px;
    .navi {
      display:block;
      .res:last-child {
        display:none;
      }
    }
  }

  @include breakpoint(lt400) {

    .res:last-of-type {
      display:none;
    }

    .company, .vat {
      display:block;
      text-align:center;
      margin:0.6em 0;
    }

  }
}

@include breakpoint(lt400) {

  .poweredbar {
    margin-top:15px;
  }
}