.ce_widgets {
  iframe.peer {
    width:100%;
    min-height:60em;
  }

  img {
    max-width:100%;
    height:auto;
  }
}