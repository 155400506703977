.ce_newsinterface {
  margin-top:3em!important;
  .subline {
    a {
      font-size: 1.6em;
      text-transform: uppercase;
      text-decoration: underline;
      &:hover {
        color:$clr-green;
      }
    }
    width:84%;
    margin:auto;
    position:relative;
    top:-7em;
  }

  @include breakpoint(lt400) {
    .subline {
      text-align: center;
    }
  }

  @include breakpoint(lt1000) {
    .subline {
      a {
        font-size: 12px;
      }
    }
  }

  .news {
    position: relative;
    padding: 0 5px;
    @include breakpoint(lt400) {
      padding: 0;
    }
    .image {
      position: relative;
      padding-top:12em;
      @include breakpoint(lt1000) {
        text-align: right;
        picture {
          max-width:70%;
        }
      }
    }

    .content {

      .rel {
        .hov {
          background-color: $clr-lgreen;
        }
      }

      .btn.stroke {
        margin-top:4em;
        @include breakpoint(lt1000) {
          margin-top:1em;
        }
      }

      position: relative;
      padding: 5em 7em;
      top: -12em;
      left: 0;
      bottom: auto;
      background: $clr-white;
      width: 80%;
      z-index: 2;
      height: 24em;

      .title {
        font-size: 2em;
        font-weight: 700;
      }

      .date {
        font-size: 1.4em;
        margin-top:0.5em;
      }

      @include breakpoint(lt1000) {

        .title {
          font-size: 16px;
        }
        .date {
          font-size: 14px;
        }
      }

      @include breakpoint(lt400) {
        padding: 5em 7em 1em 0em;
      }

      &.pre {
        display: none;
        .facts_text {
          margin-bottom: 1em;
        }
      }

      &.post {
        display: block;
        .facts_text {
          margin-top: 1em;
        }
      }
    }
  }

  .slick-slide, .slide {
    &:nth-child(2n) {
      @include breakpoint(gt400) {
        .news {

          .content {

            bottom: -12em;
            top: auto;
            right: auto;
            left: 20%;

            &.pre {
              display: block;
            }

            &.post {
              display: none;
            }



          }


          .image {
            padding-top:0em;
          }
        }
      }
      @include breakpoint(lt1000) {
        .news {
          .content {
            bottom: -8em;
          }
          .image {
            @include breakpoint(gt400) {
              text-align: left;
            }
          }
        }

      }
    }
  }

  // force relative arrow positions
  .slick-slider, .slick-track, .slick-list {
    position: static;
  }

  .auto_slide {
    max-width: 84%;
    margin: auto;
  }

  @include breakpoint(gt1000) {
    .slick-list {
      position: static;
    }

    .auto_slide {
      .slick-arrow.big {
        bottom: 7em;
      }
      .slick-dots {
        bottom: 5em;
        li {
          font-size:9em;
        }
      }
    }
  }

  @include breakpoint(lt1000) {
    .auto_slide {
      max-width: 100%;
    }
    .slick-list {
      padding-bottom: 5em;
    }
  }

  @include breakpoint(lt400) {
    .auto_slide {
      .slick-arrow.big {
        bottom: 13em;
      }
      .slick-dots {
        bottom: 11em;
      }
    }
  }

}